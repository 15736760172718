import React from "react";
import styled from "styled-components";

const VideoElement = ({ video }) => {
  
  return (
    <StyledVideo className="video-element">
      <div className="web-cam">
        <video loop={true} autoPlay={true} muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </StyledVideo>
  );
};

const StyledVideo = styled.div`
  .web-cam {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    position: relative;
    min-width: 250px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

export default VideoElement;
