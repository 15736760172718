import React from "react";
import styled from "styled-components";
import wildzwijn from "./../assets/wilde-zwijn-beer.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import { Link } from "react-router-dom";

const HomeArticle = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledHome>
        <article>
          <div>
            <img src={wildzwijn} />
          </div>
          <div className="article-copy">
            <h1>{t("home_article_title")}</h1>
            <p>{t("home_article_copy")} </p>
            <Link to="/products">{t("home_article_button")}</Link>
          </div>
        </article>
      </StyledHome>
    </>
  );
};
const StyledHome = styled.div`
  article {
    display: flex;
    flex-wrap: wrap;
    margin: 70px auto 70px auto;
    align-items: top;
    @media (min-width: 768px) {
      width: 60vw;
      margin: 120px auto 120px auto;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .article-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1400px) {
      margin-top: 0px;
    }
  }
  
  article div p {
    font-size: 1.2em;
    line-height: 24px;
    margin-bottom: 10px;
  }

  article h1 {
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
  }

  article a {
    border: solid 1px var(--link-btn-border);
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2em;
    text-decoration: none;
    color: var(--link);
  }

  article a:hover {
    background-color: var(--link-hover);
    border: solid 1px var(--link-hover);
  }
`;
export default HomeArticle;
