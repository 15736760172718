import { Routes, Route} from "react-router-dom";
import Home from "../Home";
import Hero from "../Hero";
import ProductHighlight from "../ProductHighlight";
import Productdetail from "../Productdetail";
import PageNotFound from "../PageNotFound";
import HeroContact from "../HeroContact";

const RoutesHeader = ({showItems, checkEndDate}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            {/* <Home /> */} 
            {/* <ProductHighlight showItems={showItems} /> */}
          </>
        }
      />

      <Route path="/technical" element={<Hero />} />

      <Route path="/about" element={<Hero />} />

      <Route exact path="/products" element={<ProductHighlight showItems={showItems} />} />

      <Route path="/service" element={<Hero />} />

      <Route path="/dealers" element={<Hero />} />

      <Route path="/contact" element={<HeroContact checkEndDate={checkEndDate} />} />

      <Route path="/glossary" element={<Hero />} />

      <Route path="/cookie-policy" element={<Hero />} />

      <Route path="/products/:url" element={<Productdetail />} />

      <Route path="/offers" element={<Hero />} />

      <Route path="/404" element={<PageNotFound />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesHeader