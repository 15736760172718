import React from "react";
import DataProducts from "./DataProducts";
import styled from "styled-components";
import {Helmet} from "react-helmet"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const Productoverview = ({ showItems, clickHandlerShowItem }) => {
  const { t, i18n } = useTranslation();

  const filteredList = DataProducts.filter((product) => product.type === showItems);

  return (
    <Styledoverview>
      <Helmet>
        <title>{t("menu_producten")}</title>
        <meta name="description" content="Product overview of OutdoorOptics digital and thermal imaging scopes" />
      </Helmet>

        <div className="productoverview">

          {filteredList.length > 0 && (
            <ul>
              {filteredList
                .map((product) => (
                  <Link to={`/products/${product.url /*[i18n.language]*/}`} key={product.id}>
                    <li className="content">
                      <img src={"/productafbeeldingen/" + product.images[0]} alt={product.title[i18n.language]} />
                      <div className="copy">
                        {product.new && <p className="banner">{t("specs_new")}</p>}
                        {product.available_date && <p className="available-date">{product.available_date[i18n.language]}</p>}
                        <h2>{product.title[i18n.language]}</h2>
                        {product.subtitle ? <p>({product.subtitle})</p> : ""}
                        {product.shortDes && <p>{product.shortDes[i18n.language]}</p>}
                      </div>
                      <div className="line"></div>
                    </li>
                  </Link>
                ))}
            </ul>
          )}

          {filteredList.length === 0 && (
            <p className="product-message">{t("products_not_found")}</p>
            )}

        </div>
    </Styledoverview>
  );
};

const Styledoverview = styled.div`
  margin: 10px auto 0 auto;
  max-width: 90vw;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 60vw;
  }
  .content {
    display: flex;
    border-bottom: 4px solid #fff;
    padding-bottom: 50px;
  }
  .content h2 {
    font-size: 40px;
  }

  .productoverview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .productoverview .content {
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto 30px auto;
    }
  }
  .productoverview .content .copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  .productoverview .content h2 {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    padding-top: 10px;
    border-bottom: 2px solid var(--brand-accent-color);

    @media (max-width: 1024px) {
      font-size: 28px;
    }
  }
  .productoverview .content p {
    margin-bottom: 10px;
    line-height: 24px;
    font-size: 20px;

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  .productoverview a {
    text-decoration: none;
    color: var(--text);
  }
  .productoverview a:visited,
  .productoverview a:hover,
  .productoverview a:active {
    /* color: #707070; */
  }

  ul, .product-message {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .product-message {
      font-size: 22px;
      font-style: italic;
  }
  a:last-of-type .line {
    display: none;
  }
  img {
    align-self: center;
    width: 80%;

    @media (min-width: 768px) {
      width: 70vw;
    }

    @media (min-width: 1024px) {
      width: 25vw;
    }
  }
  .productoverview .content p.banner {
    background-color: var(--brand-accent-color);
    color: var(--text);
    padding: 6px 10px;
    border-radius: 8%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .productoverview .content .available-date {
        font-style: italic;
        font-size: 18px;
    }
    
`;
export default Productoverview;
