const DataDealers = [

	{
		id: 3,
		url: "https://www.akah.de/teilnehmende-haendler/",
		logo: "akah.png",
		country: "Austria",
		   
	},
	{
		id: 4,
		url: "https://www.arms-cz.cz/",
		logo: "arms.jpg",
		country: "Czech Republic",
		   
	},
	{
		id: 5,
		url: "https://jaguargruppen.dk/butiker/",
		logo: "jaguar.png",
		country: "Denmark",
		   
	},
	{
		id: 6,
		url: "https://www.z-shops.eu/",
		logo: "z-shops.png",
		country: "Hungary",
		   
	},
	{
		id: 7,
		url: "https://www.bignami.it/it/",
		logo: "bignami.jpg",
		country: "Italie",
		   
	},
	{
		id: 1,
		url: "https://www.mikx.nl/contact/",
		logo: "mikx.png",
		country: "Netherlands",
		   
	},
	{
		id: 2,
		url: "https://www.mikx.nl/contact/",
		logo: "mikx.png",
		country: "Belgium",
		   
	},
	{
		id: 8,
		url: "https://www.fanzoj.hr/",
		logo: "fanzoj.png",
		country: "Croatia",
		   
	},
	{
		id: 9,
		url: "https://www.mikx.nl/contact/",
		logo: "mikx.png",
		country: "Luxembourg",
		   
	},
	{
		id: 10,
		url: "https://www.landro.no/",
		logo: "landro.png",
		country: "Norway",
		   
	},
	{
		id: 11,
		url: "https://huntinggear.eu",
		logo: "gear.png",
		country: "Romania",
		   
	},
	{
		id: 12,
		url: "https://www.arms-cz.cz/",
		logo: "arms.jpg",
		country: "Slovakia",
		   
	},
	{
		id: 13,
		url: "http://www.ibericadearmerias.com/",
		logo: "iberica.jpg",
		country: "Spain",
		   
	},
	{
		id: 14,
		url: "https://www.torsbohandels.com",
		logo: "torsbo.webp",
		country: "Sweden",
		   
	},
	{
		id: 15,
		url: "https://www.akah.de/teilnehmende-haendler/",
		logo: "akah.png",
		country: "Germany",
		   
	},
	
	{
		id: 16,
		url: "https://tvprzemyslowa.pl/",
		logo: "poland.jpg",
		country: "Poland",
		   
	}

    
];
export default DataDealers;