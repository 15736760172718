import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// 2022-09-08 PP: change offers to fixed 21% vat percentage, so vat is not based on country for offers page (see issue: DTPWEB-107)

const OfferCard = ({ offer, clientCountry, clientVat }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledOfferCard>
      <img src={"./offers-header-img/" + offer.img} alt={offer.title[i18n.language]} />
      <div className="card-body">
        <h2 className="card-title">{offer.title[i18n.language]}</h2>
        <div className="card-content">
          <p>{offer.desc[i18n.language]}
            <br />
            <span className="remark">{offer.remark[i18n.language]}</span>
          </p>
        </div>
      </div>
      <div className="card-price">
        <p>
          <span className="price-offer">€ {(offer.price_offer_ex_vat * (1.21)).toFixed(2)}</span>
          <br />
          <span className="price-subtext">
            ({t("vat_incl")} 21% {t("vat")})
          </span>
        </p>
      </div>
      <div className="card-order">
        <p>{t("offers_send_message_1")} <a href={`mailto:orders@outdooroptics.eu?subject=${t("order_email.subject_order")}%3A%20${offer.title[i18n.language]} ${offer.number}&body=${t("order_email.body_opening")}%0A%0A${t("order_email.body_copy_1")}%0A%0A${t("order_email.body_product_title")}${offer.title[i18n.language]}%20(${offer.number})%0A%0A${t("order_email.body_details_heading")}%0A%0A${t("order_email.body_details_name")}%0A${t("order_email.body_details_address")}%0A${t("order_email.body_details_zip")}%0A${t("order_email.body_details_city")}%0A${t("order_email.body_details_country")}%0A${t("order_email.body_details_phone")}%0A${t("order_email.body_details_email")}%0A%0A${t("order_email.body_thanks")}%0A%0A`}>orders@outdooroptics.eu</a> {t("offers_send_message_2")}
        </p>
      </div>
      <div className="card-footer">
        <a href={`mailto:orders@outdooroptics.eu?subject=${t("order_email.subject_order")}%3A%20${offer.title[i18n.language]} (${offer.number})&body=${t("order_email.body_opening")}%0A%0A${t("order_email.body_copy_1")}%0A%0A${t("order_email.body_product_title")}${offer.title[i18n.language]}%20(${offer.number})%0A%0A${t("order_email.body_details_heading")}%0A%0A${t("order_email.body_details_name")}%0A${t("order_email.body_details_address")}%0A${t("order_email.body_details_zip")}%0A${t("order_email.body_details_city")}%0A${t("order_email.body_details_country")}%0A${t("order_email.body_details_phone")}%0A${t("order_email.body_details_email")}%0A%0A${t("order_email.body_thanks")}%0A%0A`}>{offer.button_text[i18n.language]}</a>
      </div>
    </StyledOfferCard>
  );
};

const StyledOfferCard = styled.div.attrs({
  className: "card",
})`
  display: flex;
  flex-direction: column;
  min-width: 230px;
  min-height: 324px;
  width: 320px;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
  border-radius: 8px;
  background-color: var(--brand-color-dark);

  animation-name: carditem;
  animation-duration: 2.5s;
  animation-timing-function: ease-out;

  @keyframes carditem {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  p {
    line-height: 22px;
    margin-bottom: 20px;
  }

  .card-price {
    text-align: right;
    padding: 0 10px;
  }

  .card-price p {
    line-height: 26px;
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 0 10px;
    flex-grow: 1;
    flex-shrink: 0;
    margin-top: 10px;
  }

  .card-title {
    font-size: 24px;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 6px;
    margin-top: 0;
    margin-bottom: 10px;
    display: inline-block;
  }

  .card-offer {
    line-height: 24px;
  }

  .remark {
    font-style: italic;
    font-size: 16px;
  }

  .price-offer {
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 10px;
    border: 1px solid rgb(255, 255, 255);
  }

  .price-regular {
    text-decoration-line: line-through;
    text-decoration-color: var(--brand-accent-color);
    text-decoration-thickness: 2px;
  }

  .price-subtext {
    font-size: 14px;
  }

  .card-order {
    padding: 0 10px;
    font-size: 14px;
    font-style: italic;
  }

  .card-footer {
    padding: 4px 10px;
    text-align: right;
    padding-bottom: 10px;
  }

  .card-footer a {
    color: var(--text);
    text-decoration: none;
    border: 1px solid rgb(255, 255, 255);
    padding: 8px 10px;
    border-radius: 10px;
    display: inline-block;
  }

  .card-footer a:hover {
    background-color: var(--link-hover);
    border: 1px solid var(--link-hover);
  }
`;

export default OfferCard;
