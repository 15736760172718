import React from 'react';
import DataProducts from './DataProducts'
import styled from 'styled-components';
import {
    Link
  } from "react-router-dom";
  import { useTranslation } from "react-i18next";
  import "../locales/i18n";



const ProductHighlight = ({showItems}) => {
  let text = "wm11";
  switch (showItems) {
    case "night":
      text = "nz1";
      break;
    case "digital":
      text = "dg1";
      break;
      case "thermal":
        text = "wm5";
        break;
      case "accessories":
        text = "as1";
        break;
    default:
      text = "wm22";
  }
    var product = DataProducts.find(product => product.id === text)

    const { t, i18n } = useTranslation();
    const path = window.location.pathname;
  return (
    <StyledProductHighlight>
      {product !== undefined && (
        <div className={path === "/products" ? "highlight" : "home"}>
          <img src={"/productafbeeldingen/" + product.images[0]} alt={product.title[i18n.language]} />
          <div className="copy">
            <h1>{product.title[i18n.language]}</h1>
            {path === "/products" && <p>{product.spotlight[i18n.language]}</p>}
            <div className="links">
              {/* {path === "/products" && <Link to="/dealers">{t("find_dealer")}</Link>} */}
              <Link to={`/products/${product.url}`}>{t("product_detail")} </Link>
            </div>
          </div>
        </div>
      )}
    </StyledProductHighlight>
  );
}
const StyledProductHighlight = styled.div`
div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      margin-top: 20px;
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      margin-top: 50px;
      flex-wrap: no-wrap;
    }  

}
div img{
    width:90vw;
    @media (min-width: 768px) {
      width:80vwx;
    }

    @media (min-width: 1024px) {
      width:700px;
    }  
}

div div{
    max-width: 500px;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    align-items: stretch;
}

.home {
    flex-direction: column;
    margin-top: 10px;
    margin-left: 40px;
    justify-content: flex-start;
    align-items: flex-start;

    @media (min-width: 1024px) {
      margin-top: 4px;
    } 
}

.home img {

  @media (min-width: 1024px) {
      width:500px;
    }  
}

.home .copy {
  margin-left: 64px;
  margin-top: 4px;
}

div h1{
  border-bottom: 2px solid var(--brand-accent-color);
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: inline;
  color: white;
  font-size: 24px;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
}
p{
  color: white;
  font-size: 20px;
  margin-bottom: 30px;
  line-height: 22px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 24px;
  }

}
.links{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
}

.links a{
    color: white;
    text-decoration: none;
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 20px 20px 0;
    font-size: 1.4em;
  
}
.links a:hover{
  background-color: var(--brand-accent-color);
  color: white;
  border-color: var(--brand-accent-color);
}
.subtitle{
    font-size: 16px;
    margin: 0 0 30px 0;
}
`
export default ProductHighlight