import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import "../locales/i18n";
import DataProducts from "./DataProducts"
import {
    Link
  } from "react-router-dom";

const LinkProduct = ({id}) => {
    const { t, i18n } = useTranslation();
    const data = DataProducts.find(item => item.id === id)
  return (                   
                        
    <StylesLinkProduct>          
                        <Link to={`/products/${data.url}`} key={data.id} className="product-card">
                                {/*<p>{item}</p>*/}
                                    <img src={"/productafbeeldingen/"+data.images[0]}/>
                                    <p>{data.title}</p>    
                            </Link>
    </StylesLinkProduct>


  ) }
  const StylesLinkProduct = styled.div`
  .product-card{
    border: 1px solid grey;
    flex: 1 1 50%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-card img{
    max-width: 100%;
    padding: 10%;
}
.product-card p{
    color: var(--brand-accent-color);
    padding-left: 15px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
}


`;

export default  LinkProduct;


