import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import DataDealers from "./DataDealers";
import { useTranslation } from "react-i18next";
import "../locales/i18n";


function Dealers({clientCountry})  {
  const { t } = useTranslation();
   console.log("de client country is" + typeof(clientCountry) +  clientCountry)  

   let dealers = DataDealers
   .filter(dealer => dealer.country.includes(clientCountry))
   if (dealers.length === 0) {
     dealers = DataDealers
       .filter(dealer => dealer.country.includes("Netherlands"))}

  
  return (
    <StyledDealers>
      <Helmet>
        <title>Dealers</title>
        <meta name="description" content="Distributor or dealer in your country" />
      </Helmet>
        <span>
        <h1>{t("service_choise_title")}</h1>

        </span>

    <div className="cards">
                
                {dealers
                .map(dealer => (
                <a target="blanc" href={dealer.url} key={dealer.id} className="card" >   
                        <img src={"/dealers/"+dealer.logo} alt="dealer logo" />                  
                </a>
            ))}
 
      {console.log("de client country in de card is: " + clientCountry)}      
    </div>
    </StyledDealers>
  )
}
const StyledDealers = styled.div`

    max-width: 100vw;
    margin: 120px auto 0 10px;

    @media (min-width: 768px) {
        max-width: 60vw;
        margin: 120px auto 0 auto;
      }
  
      @media (min-width: 1024px) {
        max-width: 60vw;
        margin: 120px auto 0 auto;
      }
  .card img{
      width: 80%;
      padding: 12px;
      
  }
    .card{
       height: 350px;
       width: 95vw;
       @media (min-width: 768px) {
        width: 450px;
      }
  
      @media (min-width: 1024px) {
        width: 450px;
      }
       background-color: #C1B8B8;
       border: 1px solid #C1B8B8;
       margin: 0 0 50px 0;

    }
    .card p{
        text-decoration: none;
        color: #393939;
        font-size: 20px;      
    }
    .card p a{
        color: #393939;
        font-weight: bold;
    }
.card{
    background-color: #C1B8B8;
}

.cards{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;
    
    
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-direction: column;
  
      @media (min-width: 1024px) {
        margin-right: 40px;
      }
}
h1 {
    font-size: 23px;
    font-weight: bold;
    border-bottom: 2px solid var(--brand-accent-color);
    display: inline-block;
    padding-bottom: 6px;
    margin-bottom: 20px;
    
}
span{
    display: block;
    margin-bottom: 40px;
}

`
export default Dealers