import React, {useEffect} from 'react';
import DataGlossary from './DataGlossary'
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import { useTranslation } from "react-i18next";



const Glossary = ({ showItems, setShowItems, clickHandlerShowItem }) => {

  const { t, i18n } = useTranslation();

    // check if showItems is event or archive or all
    // if so, set showItem to standard thermal on page load to prevent empty tab/list 
    // (because this page does not know of event or archive or digital or accessories)
    useEffect(() => {
      if (showItems === "events" || showItems === "archive" || showItems === "digital" || showItems === "accessories" || showItems === "all") {
          setShowItems("thermal");
      }
    }, []);
        
  return (
      <Styledoverview>
        <Helmet>
          <title>{t("menu_woorden")}</title>
          <meta name="description" content="Definition of various subject-specific terms" />
        </Helmet>
        
          <div className="glossary">
          {
                <React.Fragment>
              <div className="glossaryoverview">
                  <ul>
                      {DataGlossary
                        .filter(glos => glos.type === showItems)
                        .map(item => (
                          <li key={item.id}>
                              <p>{item.title[i18n.language]}</p>
                              <p>{item.desc[i18n.language]}</p>
                          </li>


                      ))}
                  </ul>
              </div>
              </React.Fragment>
             
             
}
          </div>
      </Styledoverview>
  )
}

const Styledoverview = styled.div`
margin: 50px auto 0 auto;
    max-width: 90vw;
    font-size: 18px;
    line-height: 22px;
    @media (min-width: 768px) {
        max-width: 70vw;
      }

      @media (min-width: 1024px) {
        max-width: 50vw;
      }
    
    .glossary{
        max-width: 100vw;
        margin: 0 auto;
        @media (min-width: 768px) {
            max-width: 70vw;
          }
    
          @media (min-width: 1024px) {
            max-width: 50vw;
          }
    }
   
    ul li p:first-of-type{
        border-bottom: 2px solid var(--brand-accent-color);
        display: inline-block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    ul li p:last-of-type{
        margin-bottom: 30px;
    }

   

`

export default Glossary