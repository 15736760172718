import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const HeroContact = ({checkEndDate}) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledHeroContact>
      <div className="hero">
        <div className="heroCopy">
          <h1>{t("contact_title")}</h1>
          <div className="hero-columns">
          <address>
            <span></span>
            <p>Stephensonstraat 47, 2014 KC Haarlem</p>
                <div className="contact-mail">
                    <p>{t("contact_mail")}</p>
                    <p> <a href="mailto:info@outdooroptics.eu">info@outdooroptics.eu</a></p>
                </div>    
            </address>
            {checkEndDate("2023-01-01") ? (  
              <div className="message-closed">
                <p className="message-heading">
                  {t("message_yearend_closing.heading")}
                </p>
                <p>{t("message_yearend_closing.p1")}</p>
                <p>{t("message_yearend_closing.p2")}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </StyledHeroContact>
  );
};

const StyledHeroContact = styled.div`
  .hero {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    position: relative;

    @media (max-width: 850px) {
      flex-direction: column;
    }
  }

  h1 {
    padding-bottom: 5px;
    max-width: 400px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    border-bottom: 6px solid var(--brand-accent-color);
    margin-bottom: 10px;
  }

  .heroCopy {
    width: 100%;
    padding: 10px;
    margin-top: 250px;
    margin-bottom: 50px;
    z-index: 10;

    @media (min-width: 768px) {
      width: 90%;
    }

    @media (min-width: 1024px) {
      width: 85%;
    }

    @media (min-width: 1400px) {
      width: 70%;
    }

    @media (min-width: 1632px) {
      width: 60%;
    }
  }

  .hero-columns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @media (min-width: 930px) {
      flex-wrap: nowrap;
    }
  }

  p {
    font-size: 20px;
  }

  .hero-img {
    width: 300px;
    height: 300px;
    z-index: 1;

    @media (min-width: 1024px) {
      width: 350px;
      height: 350px;
      margin-top: 130px;
    }
  }

  address {
    font-style: normal;
    @media (min-width: 930px) {
      width: 45%;
    }
  }

  .heroCopy a {
    color: var(--link);
    font-weight: bold;
  }

  .message-closed {
    margin-top: 16px;

    @media (min-width: 930px) {
      margin-top: 0;
      width: 45%;
    }
  }
  .message-heading {
    font-weight: bold;
    text-decoration: underline;
    padding-bottom: 6px;
    font-size: 21px;
  }

`;
export default HeroContact;
