import React from 'react';
import styled from 'styled-components';
import zwijn from "./../assets/uitsnede-zwijn-in-riet.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";


const History = () => {
  const { t } = useTranslation();

  return (
    
      <StyledHistory>
          
              <div className="article-copy">
                <h1>{t("history_copy_title")}</h1>
                <p>{t("history_copy_1")}</p>
                <p>{t("history_copy_2")}</p>
                <p>{t("history_copy_3")}</p>
                <p>{t("history_copy_4")}</p>
                
              </div>  
              <div><img src={zwijn}/></div>
         
      </StyledHistory>
     
    
  )
}
const StyledHistory = styled.article`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin: 250px auto 120px auto;
    align-items: top;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      width: 60vw;
      margin: 120px auto 120px auto;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
      width: 60vw;
      margin: 120px auto 120px auto;
    }
      
  

  img{   
    width: 100%;
    height: auto;
    display: block;

  
  }

  .article-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1400px) {
      margin-top: 0px;
    }
  }
  
  div p{
    font-size: 1.2em;
    line-height: 24px;
    margin-bottom: 10px;
  }

  h1{
    font-size: 23px;
    font-weight: bold;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
  
  }
   a{
      border: solid 1px #707070;
      border-radius: 5px;
      padding: 10px;
      font-size: 1.2em;
      text-decoration: none;
      color: #393939;
  }
   a:visited, article a:active, article a:hover {
    color:#393939 ;
  }
`;
export default History