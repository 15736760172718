import React from "react";
import logo from "./../assets/OutdoorOptics-logo-rgb-WH_on_GR.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledFooter>
      <div className="sitemap-wrapper">
        <div className="sitemap">
          <ul>
            <li>
              <Link to="/products">{t("menu_producten")}</Link>
            </li>
            <li>
              <Link to="/offers">{t("menu_offers")}</Link>
            </li>
            <li>
              <Link to="/glossary">{t("menu_woorden")}</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/about">{t("menu_over")}</Link>
            </li>
            <li>
              <Link to="/service">{t("menu_service")}</Link>
            </li>
          </ul>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo OutdoorOptics" />
          </Link>
        </div>
      </div>
      <div className="fine-print">
      <ul>
          <li>
            <Link to="/cookie-policy">{t("menu_cookieverklaring")}</Link>
          </li>
        </ul>

        <p className="footerRights">
          © {new Date().getFullYear()} OutdoorOptics || {t("footer_rights")}
        </p>
        <p className="footerRights">{t("footer_price")}</p>
      </div>

    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  font-size: 20px;
  background-color: var(--footer-bg);

  .logo {
    padding: 15px 0;
    @media (max-width: 484px) {
      margin: 0 auto;
    }
  }
  .logo img {
    width: 300px;
    @media (max-width: 484px) {
      width: 275px;
    }
  }
  
  .sitemap-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;
    @media (min-width: 768px) {
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }
  .sitemap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .sitemap ul {
    margin-right: 40px;
  }
  .sitemap li {
    padding: 1em;
  }
  .sitemap li a,
  .fine-print li a {
    text-decoration: none;
    color: white;
  }
  .social-icons {
    display: flex;
    list-style-type: none;
    font-size: 1.2em;
    @media (max-width: 484px) {
      justify-content: center;
    }
  }
  .social-icons li {
    padding: 7px;
    border: 1px solid white;
    border-radius: 100%;
    margin-right: 10px;
    color: white;
  }
  .social-icons li:hover{
        background-color: var(--brand-accent-color);
        border-color: var(--brand-accent-color)
    }
  .social-icons li svg {
    width: 1.2em;
    height: 1.2em;
  }
  .social-wrapper p {
    text-align: left;
    padding: 1em 0;
    @media (max-width: 484px) {
      text-align: center;
    }
  }
  .social-wrapper {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: no-wrap;
    }
  }

  .fine-print {
    font-size: 15px;
    border-top: 1px solid var(--text);
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin: 20px auto 0 auto;
  }

  .fine-print ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .fine-print li {
    padding-left: 20px;
  }

  .footerRights {
    font-size: 15px;
    padding-bottom: 5px;
    text-align: center;
  }
`;
export default Footer;
