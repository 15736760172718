export const TRANSLATIONS_EN = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Products",
    menu_producten_nacht: "Nightvision",
    menu_producten_warm: "Thermal Imaging",
    menu_producten_acces: "Accessories",
    menu_producten_digi: "Digital",
    menu_producten_overig: "See more products",
    menu_techniek: "Technical",
    menu_over: "About us",
    menu_over_history: "History",
    menu_over_how: "How we work",
    menu_over_vacatures: "Vacancies",
    menu_over_events: "Events",
    menu_service:"Service",
    menu_offers: "Special offers",
    menu_woorden: "Glossary",
    menu_cookieverklaring: "Cookie Policy",
    menu_privacy: "Privacy policy",
    footer_volg: "Follow us",
    footer_rights: "All rights reserved",
    footer_price: "All prices are suggested retail prices incl. VAT",
    home_title: "",
    home_copy: "",
    home_button: "Read more",
    home_article_title: "Quality thermal vision",
    home_article_copy: "OutdoorOptics is a long-time supplier of nightvision, thermal and digital devices. With our 30 years of experience we test for our customers the devices that are available in the market and select the devices that comply with our high standards. These devices we offer on our website to you. We have our own in-house service department to ensure that you will always enjoy full after sales support.",
    home_article_button: "Discover our models",
    tech_intro_title: "OutdoorOptics",
    tech_intro_copy: "Thermal imaging scopes have proved indispensable to those who have experienced the usefulness of this technique. Both during the day and at night the viewers give an extra dimension to watching nature. As soon as a temperature difference can be measured somewhere, the camera will make this visible. It is therefore only thermal radiation that the camera detects. With residual light intensifiers and digital viewers the still present (visible) light at night is amplified into an image that is perfectly usable and turns night into day. The image is therefore much closer to what we are used to.",
    tech_nacht_1: "The razor-sharp image of our night vision scopes allows you to observe and address most animals and wildlife after dusk and in dim moonlight.",
    tech_nacht_2: "The classic night vision, also called brightness or residual light amplifier, uses the existing light left in the night and amplifies it into an image that is excellent to use. The night becomes almost as bright as the day.",
    tech_nacht_3: "To do this, the viewer uses moon and starlight, reflections from clouds of artificial light sources and even the natural infrared radiation present.",
    tech_nacht_4: "Because of the latter, it is also possible to obtain an image in complete darkness with the help of an Infrared radiator.",
    tech_nacht_5: "The heart of this technology is a small, analog, residual light amplifying tube that converts the few light particles via an ultra-sensitive layer into electrons that are multiplied under high voltage and made visible to our eyes via a phosphorescent screen. For the phosphor lighting, use is made of the well-known green and a black/white version, called Onyx. ",
    tech_nacht_6: "Since this is a very sensitive system it is important to use the binoculars only in the dark and not to expose the tube to light during the day.",
    tech_nacht_7: "",
    tech_nacht_8: "",
    tech_nacht_button: "Go to night vision goggles",
    tech_warm_1: "With thermal imaging, even animals that are behind, not too dense, bushes do not remain hidden. Even birds, such as crows, that sit close to the trunk or birds elsewhere in trees can be found this way.",
    tech_warm_2: "Not only in open fields but also in the forest a thermal image is an indispensable tool. From mice and small birds also squirrels, marten, foxes to the larger ungulates will not escape your attention. This allows you to detect heat or movement even at several hundred meters even in the forest. ",
    tech_warm_3: "An important point when using thermal imaging is the weather conditions. If there is little moisture in the air, performance is better than during a rainstorm. The operating distance is affected by this. The big advantage is that when a warm object is in the field of view it stands out clearly.",
    tech_warm_4: "It takes some time for the user to interpret the images properly since the image is purely composed of heat and many objects and animals look different than we are used to. This keeps the user fascinated to discover new things. ",
    tech_warm_5: "By using a small part of the infrared radiation around us, (uncooled) thermal imaging cameras can visualize precisely the temperature range of living things. This is done in a purely digital way so a lot can be done with image processing in the camera to make the radiation more visible to the user.",
    tech_warm_6: "In addition, the image can be digitally enlarged and often recorded. The uncooled thermal imaging camera has made the price and ease of use of this technique accessible to many target groups. The even more sensitive and complex cooled cameras remain, due to their high price, only available to governments and some professional users. ",
    tech_warm_7: "Most models have 4 different color modes to have a good image in almost all weather conditions.",
    tech_warm_button: "Go to thermal imagers",
    tech_digi_1: "In addition to classic night vision technology, OutdoorOptics also has digital residual light amplifiers.",
    tech_digi_2: "These are highly sensitive CCD/camera sensors that are comparable to digital camera sensors and are therefore also usable during the day. For a good view under the darkest conditions, an infrared emitter is needed, which OutdoorOptics also has in its assortment. ",
    tech_digi_3: "The big advantage is the lower cost so that this technique is very accessible to many.",
    tech_digi_button: "Go to digital-imagers",
    history_copy_title: "About us",
    history_copy_1: "OutdoorOptics is a long-time supplier of nightvision, thermal and digital devices. With our 30 years of experience we test for our customers the devices that are available in the market and select the devices that comply with our high standards.",
    history_copy_2: "These devices we offer on our website to you. We have our own in-house service department to ensure that you will always enjoy full after sales support.",
    history_copy_3: "",
    history_copy_4: "",
    work_1_title: "Dust Free",
    work_1_copy: "The most important factor when mounting a high quality Lahoux night vision optic, such as the Lahoux LV-81 or the Lahoux Hemera, is an absolutely dust-free and clean laboratory atmosphere. Cleaning the lenses is a top priority. Even the smallest impurities are detected during quality control and the device is sorted out.",
    work_2_title: "Assembly",
    work_2_copy: "Our employees love Lahoux products. That's why they pay attention to maximum precision during assembly. A precision that you will then experience during the hunt. Minimal point of impact shift, maximum return accuracy.",
    work_3_title: "Quality Control",
    work_3_copy: "Every Lahoux unit goes through the strict quality control. Here, every function and dimensional accuracy is checked again. Only units that meet the standards 100 percent leave the production line. ",
    work_4_title: "High precision",
    work_4_copy: "We use high-precision collimation systems in quality control for the maximum precision and the the highest accuracy of our Lahoux attachements. You can have complete confidence in this when shooting. ",
    work_5_title: "ISO certificate",
    work_5_copy: "OutdoorOptics has the ISO 9001.2015 certificate, which means that we at OutdoorOptics are able to continuously evaluate and improve our processes, products and services. We are therefore 100% compliant with all obligations of this relevant ISO standard. The ISO standard sets requirements for the quality management of our organization. In short, we must indicate what we do and what the ISO standard says we must do, and we must also be able to prove these things. In this way our customers know what they buy and are assured that OutdoorOptics delivers consistent quality.", 
    events_title: "Events",
    events_none: "There are currently no events scheduled.",
    events_button: "More information",
    offers_hero_title: "Special offers",
    offers_hero_copy: "",
    offers_page_heading: "Special offers",
    offers_send_message_1: "Send a message to",
    offers_send_message_2: "if you would like to purchase this item or click on the order button.",
    service_title: "Servicebeleid",
    service_copy_1: "With normal use, your OutdoorOptics devices will not require maintenance for many years. If you are an end user and you have a problem with your OutdoorOptics devices, please contact your dealer.",
    service_copy_2: "Are you a dealer and do you have OutdoorOptics devices that are not functioning properly? Please ",
    service_copy_button: "contact",
    service_copy_3: " the service department of OutdoorOptics.",
    service_choise_title: "Dealer in your country",
    service_choise: "Select your country",
    specs_description: "Description",
    specs_voordelen: "Advantages",
    specs_warning: "*Warning",
    specs_warning_p: "Please note the legal regulations in your respective federal state or country.",
    specs_leveromvang: "Comes standard with the following accessories:",
    specs_tech: "Technical specifications",
    specs_accessoires: "Accessories with this product",
    specs_related: "Related products",
    specs_compatible: "Suitable for", 
    specs_new: "New",
    specs_artNum: "Article number",
    specs_color: "Unlike thermal imaging binoculars, you cannot change color while spotting. You permanently choose 1 color modes.",
    specs_groen: "Green",
    specs_fom_voor: "The",
    specs_fom: "is available with various residual light amplifier picture tubes (FOM).",
    specs_spots_veel: "Much",
    specs_spots_weinig: "Little",
    specs_spots_geen: "Almost none",
    specs_price: "The price differs with each picture tube / color mode combination. Choose the combination that suits you best.",
    vat_incl: "incl.",
    vat: "VAT",
    find_dealer: "Find Dealer",
    product_detail: "Product details",
    contact_title: "Address",
    contact_tel: "Phone",
    contact_p: "You can only visit our office by invitation. We do not have a shop or showroom.",
    contact_mail: "Email",
    contact_dealers: "Dealers only",
    events_copy_1: "",
    events_copy_2: "",
    events_copy_3: "",
    cookie_h1: "OutdoorOptics uses cookies",
    cookie_p: "OutdoorOptics uses functional and anonymized analytical cookies on this website. We currently do not set cookies from third-party websites and do not collect personal data from visitors. You can read more in our",
    cookie_consent: "Close",
    products_not_found: "No products found for this category",
    Page_not_found: "The page you were looking for no longer exists, continue to home.",
    Netherlands: "The Netherlands",
    Austria: "Austria",
    Belgium: "Belgium",
    Bulgaria: "Bulgaria",
    Cyprus: "Cyprus",
    Czech_republic: "Czech Republic",
    Denmark: "Denmark",
    Estonia: "Estonia",
    Finland: "Finland",
    France: "France",
    Germany: "Germany",
    Greece: "Greece",
    Hungary: "Hungary",
    Ireland: "Ireland",
    Italy: "Italy",
    Croatia: "Croatia",
    Latvia: "Latvia",
    Lithuania: "Lithuania",
    Luxembourg: "Luxembourg",
    Malta: "Malta",
    Poland: "Poland",
    Portugal: "Portugal",
    Romania: "Romania",
    Slovakia: "Slovakia",
    Sweden: "Sweden",
    United_kingdom: "United Kingdom",

    order_email: {
        subject_order: "Order",
        body_opening: "Dear customer,",
        body_copy_1: "If you like to order this item, please fill in your details below and send this email.",
        body_product_title: "Product: ",
        body_details_heading: "Please provide your details:",
        body_details_name: "Name: ",
        body_details_address: "Shipping address: ",
        body_details_zip: "Zip code: ",
        body_details_city: "City: ",
        body_details_country: "Country: ",
        body_details_phone: "Phone number: ",
        body_details_email: "Email: ",
        body_thanks: "Thank you for your order!",
    },

    cookie_policy: {
        page_heading: "Cookie Policy",
        page_hero_copy: "",
        intro_p1: "OutdoorOptics uses functional and analytical cookies and local storage on this website.",
        intro_p2: "A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you visit this website for the first time. OutdoorOptics uses cookies with a purely technical functionality. They ensure that the website works properly and that, for example, your preferences are remembered. These cookies are also used to make the website work properly and to optimize it.",
        intro_p3: "In addition, we place analytical cookies. Analytical cookies collect data and statistics (often anonymously) to understand how visitors use the website, so we can improve the website and our services.",
        uitschakelen_heading: "Enabling and disabling cookies and deleting them",
        uitschakelen_p1: "You can opt out of cookies by setting your Internet browser to no longer store cookies. In addition, you can also delete all information previously stored via your browser settings.",
        uitschakelen_p2: "More information about enabling, disabling and deleting cookies can be found in the instructions of your browser and/or by using the Help function of your browser.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Through our website, a cookie is placed by the American company Google, as part of the 'Analytics' service. We use this service to track and get reports about how visitors use the website. We use this information to make decisions about the design of our website. We also use this information to optimize the functioning of the website. We have taken the following measures to ensure careful processing:",
        analytics_listitem1: "We have entered into a processor agreement with Google;",
        analytics_listitem2: "The information that Google collects is anonymized as much as possible. Your IP address is explicitly not provided;",
        analytics_listitem3: "We have turned off all options offered by Google to 'share data with Google';",
        analytics_listitem4: "We do not use other Google services in conjunction with the Google Analytics cookies.",
        analytics_p2: "Google may provide this information to third parties if Google is legally required to do so, or to the extent that third parties process the information on Google's behalf. We have no influence on this. We have not allowed Google to use the obtained analytics information for other Google services.",
        analytics_p3: "The information that Google collects is anonymized as much as possible. Your IP address is explicitly not provided.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local strorage is a form of local data storage, and is intended to make the website work properly. We only use local storage to remember your chosen language of the website in your browser. We also use Local storage to remember that you have closed the cookie banner so that it will not be displayed on your next visit.",
        session_storage_heading: "Session storage",
        session_storage_p1: "Session storage is a form of temporary storage of data, and is intended to make the website work properly. We only use session storage to store information about the chosen language of the website in your browser. The session storage is deleted after you close your browser.",
    },

    privacy: {
        part1: "Test deel 1",
        part2: "<p>Test paragraph <br> Dit is een nieuwe regel. <h2>Tussenkop</h2> dit is een stuk <strong>vette tekst</strong> op de pagina.</p>",
        part3: "<strong>een vette tekst</strong> op de pagina"
    },

    message_yearend_closing: {
        heading: "Year-end closing",
        p1: "OutdoorOptics will be closed from Saturday, December 24, 2022 to Sunday, January 1, 2023.",
        p2: "We will be open again from Monday, January 2, 2023.",
    }

   };