import React from "react";
import DataProducts from "./DataProducts";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PageNotFound from "./PageNotFound";

export const productArray = (url) => {
  return DataProducts.find((item) => {
    return item.url === url;
  });
};

function Productdetail(props) {
  const { url } = useParams();
  const data = productArray(url);

  return (
    <StyledDetail>
      {data === undefined ? (
        <PageNotFound />
      ) : (
        <div className="carousel">
          <AliceCarousel autoPlay autoPlayInterval="3000" infinite disableButtonsControls>
            {data.images.map((item) => (
              <img key={item} src={"/productafbeeldingen/" + item} alt={item.replace(/\.[^.]*$/, "")} className="sliderimg" />
            ))}
          </AliceCarousel>
        </div>
      )}
    </StyledDetail>
  );
}

const StyledDetail = styled.div`
  max-width: 100vw;

  .carousel {
    @media (min-width: 768px) {
      max-width: 60vw;
    }

    @media (min-width: 1024px) {
      max-width: 40vw;
    }
    margin: 100px auto 0 auto;
  }

  img {
    width: 100%;
  }
  .sliderimg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (min-width: 768px) {
      width: 80%;
    }

    @media (min-width: 1024px) {
      width: 80%;
    }
  }
  .alice-carousel__dots {
    background-image: url("./icoonlahoux.png");
  }
  .alice-carousel__dots-item {
    width: 12px;
    height: 12px;
    margin-top: -150px;
    /* position: absolute; */
    bottom: -50px;
    @media (min-width: 768px) {
      bottom: -50px;
    }

    @media (min-width: 1400px) {
      bottom: 100px;
    }
    background-color: rgba(250, 250, 250, 0);
    border: 2px solid white;
    margin: 0px;
    right: 150px;
    background-blend-mode: inherit;
    background-image: url("./icoonlahoux.png");
  }
  .alice-carousel__dots-item:hover {
    background-color: white;
  }
  .alice-carousel__dots-item:nth-of-type(1) {
    right: 250px;
  }
  .alice-carousel__dots-item:nth-of-type(2) {
    right: 230px;
  }
  .alice-carousel__dots-item:nth-of-type(3) {
    right: 210px;
  }
  .alice-carousel__dots-item:nth-of-type(4) {
    right: 210px;
  }
  .alice-carousel__dots-item.__active {
    background-image: url("./icoonlahoux.png");
    background-color: white;
  }
  .alice-carousel__dots-item.__custom {
    background-image: url("./icoonlahoux.png");
  }
`;
export default Productdetail;
