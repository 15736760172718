export const TRANSLATIONS_DE = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Produkte",
    menu_producten_nacht: "Nachtsicht",
    menu_producten_warm: "Wärmebild",
    menu_producten_acces: "Zubehör",
    menu_producten_digi: "Digital",
    menu_producten_overig: "Mehr Produkte ansehen",
    menu_techniek: "Technik",
    menu_over: "Über uns",
    menu_over_history: "Geschichte",
    menu_over_how: "Wie wir arbeiten",
    menu_over_vacatures: "Stellenangebote",
    menu_over_events: "Messe / Events",
    menu_service:"Service",
    menu_offers: "Spezielle Angebote",
    menu_woorden: "Glossar",
    menu_cookieverklaring: "Cookie-Richtlinie",
    menu_privacy: "Datenschutz-Bestimmungen",
    footer_volg: "Folge uns",
    footer_rights: "Alle Rechte vorbehalten",
    footer_price: "Alle Preise sind unverbindliche Preisempfehlungen inkl. MwSt.",
    home_title: "",
    home_copy: "",
    home_button: "Weiterlesen",
    home_article_title: "Qualitativ hochwertiges thermisches Sehen",
    home_article_copy: "OutdoorOptics ist ein langjähriger Anbieter von Nachtsicht-, Wärmebild- und Digitalgeräten. Mit unserer 30-jährigen Erfahrung testen wir für unsere Kunden die auf dem Markt erhältlichen Geräte und wählen die Geräte aus, die unseren hohen Ansprüchen genügen. Diese Geräte bieten wir Ihnen auf unserer Website an. Wir verfügen über eine eigene Service-Abteilung, um sicherzustellen, dass Sie auch nach dem Kauf eine umfassende Unterstützung erhalten.",
    home_article_button: "Entdecken Sie unsere Modelle",
    tech_intro_title: "OutdoorOptics",
    tech_intro_copy: "Wärmebildbetrachter haben sich für diejenigen, die die Vorteile dieser Technik kennengelernt haben, als unverzichtbar erwiesen. Sowohl am Tag als auch in der Nacht verleihen die Zuschauer der Naturbeobachtung eine zusätzliche Dimension. Sobald irgendwo ein Temperaturunterschied gemessen werden kann, macht die Kamera diesen sichtbar. Die Kamera erfasst also nur die Wärmestrahlung. Mit Restlichtverstärkern und digitalen Betrachtern wird das (sichtbare) Licht, das in der Nacht noch vorhanden ist, zu einem perfekt nutzbaren Bild verstärkt, das die Nacht fast zum Tag macht. Das Bild ist also viel näher an dem, was wir gewohnt sind. ",
    tech_nacht_1: "Das gestochen scharfe Bild unserer Nachtsichtgeräte ermöglicht es Ihnen, die meisten Tiere und Wildtiere nach Einbruch der Dunkelheit und bei schwachem Mondlicht zu beobachten und anzuvisieren.",
    tech_nacht_2: "Das klassische Nachtsichtgerät, auch Helligkeits- oder Restlichtverstärker genannt, nutzt das vorhandene Licht, das in der Nacht noch vorhanden ist, und verstärkt es zu einem hervorragend verwertbaren Bild. Die Nacht wird fast so hell wie der Tag.",
    tech_nacht_3: "Der Betrachter nutzt Mond- und Sternenlicht, Wolkenreflexionen von künstlichen Lichtquellen und sogar natürliche Infrarotstrahlung.",
    tech_nacht_4: "Letzteres ermöglicht es auch, mit Hilfe eines Infrarotstrahlers ein Bild in völliger Dunkelheit zu erhalten.",
    tech_nacht_5: "Herzstück dieser Technologie ist eine kleine, analoge Restlichtverstärkerröhre, die die wenigen Lichtteilchen über eine ultraempfindliche Schicht in Elektronen umwandelt, die unter Hochspannung vervielfacht und über einen phosphoreszierenden Bildschirm für unser Auge sichtbar gemacht werden.",
    tech_nacht_6: "Für die Phosphorbeleuchtung werden das bekannte Grün und eine schwarz/weiße Version namens Onyx verwendet. ",
    tech_nacht_7: "Da es sich um ein sehr empfindliches System handelt, ist es wichtig, das Fernglas nur im Dunkeln zu benutzen und den Tubus tagsüber nicht dem Licht auszusetzen.",
    tech_nacht_8: "",
    tech_nacht_button: "Weiter zu Nachtsichtbrillen",
    tech_warm_1: "Mit der Wärmebildtechnik bleiben auch Tiere, die sich hinter nicht zu dichtem Gebüsch verstecken, nicht verborgen. Sogar Vögel wie Krähen, die in der Nähe des Stammes sitzen, oder Vögel an anderen Stellen in Bäumen können auf diese Weise gefunden werden.",
    tech_warm_2: "Nicht nur auf freiem Feld, sondern auch im Wald ist ein Wärmebild ein unverzichtbares Hilfsmittel. Von Mäusen und kleinen Vögeln über Eichhörnchen, Wühlmäuse, Füchse bis hin zu größeren Huftieren wird Ihnen nichts entgehen. Dadurch können Wärme oder Bewegung auch auf mehrere hundert Meter Entfernung, selbst im Wald, erkannt werden. ",
    tech_warm_3: "Ein wichtiger Punkt beim Einsatz der Wärmebildtechnik sind die Wetterbedingungen. Wenn die Luftfeuchtigkeit gering ist, ist die Leistung besser als bei einem Regenschauer. Dies wirkt sich auf die Reichweite aus. Der große Vorteil ist, dass ein warmes Objekt im Sichtfeld deutlich hervorsticht.",
    tech_warm_4: "Es dauert einige Zeit, bis der Benutzer die Bilder richtig interpretieren kann, da das Bild nur aus Wärme besteht und viele Objekte und Tiere anders aussehen als wir es gewohnt sind. So bleibt der Nutzer fasziniert, neue Dinge zu entdecken. ",
    tech_warm_5: "Indem sie einen kleinen Teil der uns umgebenden Infrarotstrahlung nutzen, können (ungekühlte) Wärmebildkameras den Temperaturbereich von Lebewesen sichtbar machen. Dies geschieht auf rein digitale Weise, so dass mit der Bildverarbeitung in der Kamera viel getan werden kann, um die Strahlung für den Benutzer besser sichtbar zu machen.",
    tech_warm_6: "Darüber hinaus kann das Bild digital vergrößert und oft auch aufgezeichnet werden. Die ungekühlte Wärmebildkamera hat den Preis und die Benutzerfreundlichkeit dieser Technik für viele Zielgruppen zugänglich gemacht. Aufgrund ihres hohen Preises sind die noch empfindlicheren und komplexeren gekühlten Kameras nur für Behörden und einige professionelle Nutzer erhältlich. ",
    tech_warm_7: "Die meisten Modelle verfügen über 4 verschiedene Farbmodi, um bei fast allen Wetterbedingungen ein gutes Bild zu gewährleisten.",
    tech_warm_button: "Weiter zu Wärmebildkameras",
    tech_digi_1: "Neben der klassischen Nachtsichttechnik verfügt OutdoorOptics auch über digitale Restlichtverstärker.",
    tech_digi_2: "Dabei handelt es sich um sehr empfindliche CCD-/Kamerasensoren, die mit den Sensoren von Digitalkameras vergleichbar sind und daher auch tagsüber verwendet werden können. Für eine gute Sicht unter den dunkelsten Bedingungen wird ein Infrarotstrahler benötigt, den OutdoorOptics ebenfalls in seinem Sortiment hat. ",
    tech_digi_3: "Der große Vorteil sind die geringeren Kosten, so dass diese Technik für viele sehr zugänglich ist.  ",
    tech_digi_button: "Weiter zu digital-kameras",
    history_copy_title: "Über uns",
    history_copy_1: "OutdoorOptics ist ein langjähriger Anbieter von Nachtsicht-, Wärmebild- und Digitalgeräten. Mit unserer 30-jährigen Erfahrung testen wir für unsere Kunden die auf dem Markt erhältlichen Geräte und wählen die Geräte aus, die unseren hohen Ansprüchen genügen.",
    history_copy_2: "Diese Geräte bieten wir Ihnen auf unserer Website an. Wir verfügen über eine eigene Service-Abteilung, um sicherzustellen, dass Sie auch nach dem Kauf eine umfassende Unterstützung erhalten.",
    history_copy_3: "",
    history_copy_4: "",
    work_1_title: "Staubfrei",
    work_1_copy: "Der wichtigste Faktor bei der Montage einer hochwertigen Lahoux Nachtsichtoptik, wie beispielsweise das Lahoux LV-81 oder das Lahoux Hemera, ist eine absolut staubfreie und reine Laboratmosphäre. Die Reinigung der Linsen hat höchste Priorität. Schon kleinste Verunreinigungen werden bei der Qualitätskontrolle entdeckt und das Gerät wird aussortiert.",
    work_2_title: "Montage",
    work_2_copy: "Unsere Mitarbeiter lieben die Lahoux Produkte. Daher achten sie bei der Montage auf maximale Präzision. Eine Präzision, die Sie bei der Jagd dann erleben. Geringste Treffpunktverlagerung, maximale Wiederkehrgenauigkeit.",
    work_3_title: "Qualitätskontrolle",
    work_3_copy: "Jedes Lahoux Gerät durchläuft die strenge Qualitätskontrolle. Hier wird nochmals jede Funktion und Maßhaltigkeit geprüft. Nur Geräte die die Normen zu 100 Prozent erfüllen, verlassen die Fertigung. ",
    work_4_title: "Hochpräzise",
    work_4_copy: "Wir setzen hochpräzise Kollimationssysteme in der Qualitätskontrolle für die maximale Präzision unserer Lahoux Vorsatzgeräte ein. Darauf können Sie sich beim Schuss voll vertrauen. ", 
    work_5_title: "ISO zertifiziert",
    work_5_copy: "OutdoorOptics ist nach ISO 9001.2015 zertifiziert, was bedeutet, dass wir bei OutdoorOptics in der Lage sind, unsere Prozesse, Produkte und Dienstleistungen kontinuierlich zu bewerten und zu verbessern. Wir erfüllen daher zu 100 % alle Verpflichtungen dieser einschlägigen ISO-Norm. Die ISO-Norm stellt Anforderungen an das Qualitätsmanagement unserer Organisation. Kurz gesagt, wir müssen angeben, was wir tun und was wir nach der ISO-Norm tun müssen, und wir müssen auch in der Lage sein, diese Dinge zu beweisen. Auf diese Weise wissen unsere Kunden, was sie kaufen und haben die Gewissheit, dass OutdoorOptics gleichbleibende Qualität liefert.", 
    events_title: "Messe",
    events_none: "Derzeit sind keine Veranstaltungen geplant.",
    events_button: "Mehr Informationen",
    offers_hero_title: "Spezielle Angebote ",
    offers_hero_copy: "",
    offers_page_heading: "Spezielle Angebote ",
    offers_send_message_1: "Senden Sie eine Nachricht an",
    offers_send_message_2: ", wenn Sie diesen Artikel kaufen möchten, oder klicken Sie auf den Bestellbutton.",
    service_title: "Servicebeleid",
    service_copy_1: "Bei normalem Gebrauch ist Ihr OutdoorOptics Gerät viele Jahre wartungsfrei. Wenn Sie als Endbenutzer ein Problem mit Ihrem OutdoorOptics Gerät haben, wenden Sie sich bitte an Ihren Fachhändler.",
    service_copy_2: "Sie sind Händler und haben Sie ein nicht richtig funktionierendes OutdoorOptics Gerät? Bitte ",
    service_copy_button: "wenden",
    service_copy_3: " Sie sich an die Serviceabteilung von OutdoorOptics.",
    service_choise_title: "Händler in Ihrem Land",
    service_choise: "Wähle dein Land", 
    specs_description: "Beschreibung",
    specs_voordelen: "Merkmale",
    specs_warning: "*Warnung",
    specs_warning_p: "Bitte beachten Sie die gesetzlichen Bestimmungen in ihrem jeweiligen Bundesland beziehungsweise Land.",
    specs_leveromvang: "Wird standardmäßig mit folgendem Zubehör geliefert:",
    specs_tech: "Technische Spezifikationen", 
    specs_accessoires: "Mitgeliefertes Zubehör",
    specs_related: "Verwandte Produkte",
    specs_compatible: "Passend für",
    specs_new: "Neu",
    specs_artNum: "Artikelnummer",
    specs_color: "Im Gegensatz zu einem Wärmebildgerät können Sie beim Spotten die Farbe nicht ändern. Sie wählen dauerhaft 1 Farbmodi.",
    specs_groen: "Grün",
    specs_fom_voor: "Der",
    specs_fom: "ist mit verschiedenen Restlichtverstärker-Bildröhren (FOM) erhältlich.",
    specs_spots_veel: "Viel",
    specs_spots_weinig: "Wenig",
    specs_spots_geen: "Fast keine",
    specs_price: "Der Preis ist je nach Bildröhren-/Farbmodus-Kombination unterschiedlich. Wählen Sie die Kombination, die am besten zu Ihnen passt.",
    vat_incl: "inkl.",
    vat: "MwSt",
    find_dealer: "Händler finden",
    product_detail: "Produktdetails", 
    contact_title: "Anschrift",
    contact_tel: "Telefon",
    contact_p: "Sie können unser Büro nur auf Einladung besuchen. Wir haben keinen Shop oder Showroom.",
    contact_mail: "E-Mail",
    contact_dealers: "Nur für Händler",
    events_copy_1: "",
    events_copy_2: "",
    cookie_h1: "OutdoorOptics verwendet Cookies",
    cookie_p: "OutdoorOptics verwendet auf dieser Website funktionale und anonymisierte analytische Cookies. Wir setzen derzeit keine Cookies von Websites Dritter und sammeln keine personenbezogenen Daten von Besuchern. Weitere Informationen finden Sie in unserer",
    cookie_consent: "Schließen",
    products_not_found: "Keine Produkte für diese Kategorie gefunden",
    Page_not_found: "Die gewünschte Seite existiert nicht mehr. Bitte gehen Sie zurück zur Startseite.",
    Netherlands: "Niederlande",
    Austria: "Österreich",
    Belgium: "Belgien",
    Bulgaria: "Bulgarien",
    Cyprus: "Zypern",
    Czech_republic: "Tschechien",
    Denmark: "Dänemark",
    Estonia: "Estland",
    Finland: "Finnland",
    France: "Frankreich",
    Germany: "Deutschland",
    Greece: "Griechenland",
    Hungary: "Ungarn",
    Ireland: "Irland",
    Italy: "Italien",
    Croatia: "Kroatien",
    Latvia: "Lettland",
    Lithuania: "Litauen",
    Luxembourg: "Luxemburg",
    Malta: "Malta",
    Poland: "Polen",
    Portugal: "Portugal",
    Romania: "Rumänien",
    Slovakia: "Slowakei",
    Sweden: "Schweden",
    United_kingdom: "Vereinigtes Königreich",

    order_email: {
        subject_order: "Bestellung",
        body_opening: "Sehr geehrter Kunde,",
        body_copy_1: "Wenn Sie diesen Artikel bestellen möchten, geben Sie bitte unten Ihre Daten ein und senden Sie diese E-Mail.",
        body_product_title: "Produkt: ",
        body_details_heading: "Bitte geben Sie Ihre Daten an:",
        body_details_name: "Name: ",
        body_details_address: "Versandadresse: ",
        body_details_zip: "Postleitzahl: ",
        body_details_city: "Stadt: ",
        body_details_country: "Land: ",
        body_details_phone: "Telefonnummer: ",
        body_details_email: "E-Mail: ",
        body_thanks: "Vielen Dank für Ihre Bestellung!",
    },

    cookie_policy: {
        page_heading: "Cookie-Richtlinie",
        page_hero_copy: "",
        intro_p1: "Lahoux Optics verwendet auf dieser Website funktionale und analytische Cookies und Local Storage.",
        intro_p2: "Ein Cookie ist eine kleine Textdatei, die im Browser Ihres Computers, Tablets oder Smartphones gespeichert wird, wenn Sie diese Website von Lahoux Optics zum ersten Mal besuchen. Lahoux Optics verwendet Cookies mit rein technischer Funktionalität. Diese Cookies stellen sicher, dass die Website ordnungsgemäß funktioniert und merken sich beispielsweise Ihre bevorzugten Einstellungen. Die funktionalen Cookies sind notwendig, damit die Website ordnungsgemäß funktioniert und, um die Leistung zu optimieren.",
        intro_p3: "Darüber hinaus platzieren wir analytische Cookies. Unsere Analyse-Cookies enthalten keine personenbezogenen Daten und erheben auch keine. Sie werden verwendet, um anonyme Informationen über die Nutzung unserer Websites zu sammeln, um uns dabei zu helfen, unsere Dienste kontinuierlich zu verbessern.",
        uitschakelen_heading: "Cookies aktivieren und deaktivieren und löschen",
        uitschakelen_p1: "Sie können Cookies ablehnen, indem Sie Ihren Internetbrowser so einstellen, dass er keine Cookies mehr speichert. Darüber hinaus können Sie auch alle zuvor gespeicherten Informationen über Ihre Browsereinstellungen löschen.",
        uitschakelen_p2: "Weitere Informationen zum Aktivieren, Deaktivieren und Löschen von Cookies finden Sie in den Anweisungen Ihres Browsers und/oder über die Hilfefunktion Ihres Browsers.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Über unsere Website wird ein Cookie von der amerikanischen Firma Google als Teil des Dienstes 'Analytics' platziert. Wir nutzen diesen Dienst, um Berichte darüber zu sammeln, wie Besucher die Website nutzen.  Wir verwenden diese Informationen, um Entscheidungen über die Gestaltung unserer Website zu treffen. Wir verwenden diese Informationen auch, um das Funktionieren der Website zu optimieren. Wir haben folgende Maßnahmen ergriffen, um eine sorgfältige Verarbeitung zu gewährleisten:",
        analytics_listitem1: "Wir haben eine Auftragsverarbeitungsvereinbarung mit Google abgeschlossen.",
        analytics_listitem2: "Die Informationen, die Google sammelt, werden so weit wie möglich anonymisiert. Ihre IP-Adresse ist ausdrücklich nicht angegeben.",
        analytics_listitem3: "Wir haben alle von Google angebotenen Optionen zum 'Teilen von Daten mit Google' deaktiviert.",
        analytics_listitem4: "Wir verwenden keine anderen Google-Dienste in Verbindung mit den Google Analytics-Cookies.",
        analytics_p2: "Google wird diese Informationen gegebenenfalls an Dritte weitergeben, sofern Google gesetzlich dazu verpflichtet ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Darauf haben wir keinen Einfluss. Wir haben Google nicht gestattet, die erhaltenen Analysedaten für andere Google-Dienste zu verwenden.",
        analytics_p3: "Die Informationen, die Google sammelt, werden so weit wie möglich anonymisiert. Ihre IP-Adresse wird ausdrücklich nicht angegeben.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local storage ist eine Form der lokalen Datenspeicherung und dient dazu, dass die Website ordnungsgemäß funktioniert. Wir verwenden Local storage nur, um die von Ihnen gewählte Sprache der Website in Ihrem Browser zu speichern. Wir verwenden auch Local storage, um zu wissen, dass Sie das Cookie-Banner geschlossen haben, damit es bei Ihrem nächsten Besuch nicht mehr angezeigt wird.",
        session_storage_heading: "Session Storage",
        session_storage_p1: "Session Storage ist eine Form der temporären Speicherung von Daten und soll dazu dienen, dass die Website ordnungsgemäß funktioniert. Wir verwenden Session Storage nur, um Informationen über die gewählte Sprache der Website in Ihrem Browser zu speichern. Die Session Storage werden beim Schließen Ihres Browsers automatisch gelöscht.",
    },
    
   };