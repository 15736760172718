import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import zwijn from "./../assets/uitsnede-zwijn-in-riet.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";


const About = () => {
  const { t } = useTranslation();

  return (

      <StyledAbout>
        <Helmet>
          <title>{t("history_copy_title")}</title>
          <meta name="description" content="OutdoorOptics is a long-time supplier of nightvision, thermal and digital devices with 30 years of experience." />
        </Helmet>
        
              <div className="article-copy">
                <h1>{t("history_copy_title")}</h1>
                <p>{t("history_copy_1")}</p>
                <p>{t("history_copy_2")}</p>
                <p>{t("history_copy_3")}</p>
                <p>{t("history_copy_4")}</p>
                
              </div>  
              <div><img src={zwijn}/></div>

         
      </StyledAbout>
    
  )
}
const StyledAbout = styled.article`

    display: flex;
    flex-wrap: wrap;
    margin: 70px auto 70px auto;
    align-items: top;
    @media (min-width: 768px) {
      width: 60vw;
      margin: 120px auto 120px auto;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }   
  

  img{   
    width: 100%;
    height: auto;
    display: block;

  
  }

  .article-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1400px) {
      margin-top: 0px;
    }
  }
  
  div p{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  h1{
    font-size: 23px;
    font-weight: bold;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
  
  }

  article a {
    border: solid 1px var(--link-btn-border);
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2em;
    text-decoration: none;
    color: var(--link);
  }

  article a:hover {
    background-color: var(--link-hover);
    border: solid 1px var(--link-hover);
  }

`
export default About