import React from 'react';
import styled from 'styled-components';
import varkens from "./../assets/varkens.mp4";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import {
  Link
} from "react-router-dom";




const Home = () => {
  const { t } = useTranslation();


  return (
    
      <StyledHome>
       <div className="hero">   
         
            
          <div className="heroCopy">
              <h1>{t("home_title")}</h1>
              <div className="phatline"></div>
              <p>{t("home_copy")}<Link to="/techniek"> {t("home_button")}</Link></p>
          </div>
          {/* <div className="web-cam">
            <video loop={true} autoPlay={true} muted>
                <source src={varkens} type="video/mp4"></source>
                  
            </video>
          </div>      */}
        </div>
            
      </StyledHome>
     
    
  )
}
const StyledHome = styled.div`
  position: relative;
  z-index: 1;
    .hero{
      max-width: 100vw;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      margin: 0px auto;
        @media (min-width: 768px) {
          margin: 30px auto;
          flex-wrap: wrap;
        }

        @media (min-width: 1024px) {
          margin: 30px auto;
          flex-wrap: no-wrap;
        }  
    }
    
   h1{
       border-bottom: 4px solid var(--brand-accent-color);
       padding-bottom: 6px;
       margin-bottom: 10px;
       display: inline;
       color: white;
       font-size: 32px;
       font-weight: bold;
       margin-top: 20px;
       @media (min-width: 768px) {
        margin-top: 0px;
        color: white;
      }
  
      @media (min-width: 1024px) {
        margin-top: 0px;
        
      }
   }
   .heroCopy{
       display: flex;
       flex-direction: column;
       max-width: 500px;
       padding: 15px;
       margin-left: 0px;
      
       margin-top: 0px;
       @media (min-width: 768px) {
        margin-left: 30px;
        margin-top: 0px;
      }
  
      @media (min-width: 1024px) {
        margin-left: 30px;
        margin-top: 130px;
      }
       align-self: center;
       
       
       
   }
   p{
       color: white;
       font-size: 1.4em;
       margin-bottom: 40px;
       line-height: 26px;
       @media (min-width: 768px) {
        color: white;
      }
  
      @media (min-width: 1024px) {
        color: white;
      }
   }
   p a{
     font-weight: bold;
     text-decoration: underline;
     color: white;
   }
   p a:active, p a:hover, p a:visitid{
     color: white;
   }

   .web-cam { 
    width: 300px;
    height: 300px;
    z-index: 1;
    @media (min-width: 768px) {
      width: 300px;
      height: 300px;
    }

    @media (min-width: 1024px) {
      width: 450px;
      height: 450px;
    }
    border-radius: 250px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
  }
  
  
`;
export default Home