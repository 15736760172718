import React from "react";
import { Routes, Route} from "react-router-dom";
import HomeArticle from "../HomeArticle";
import SelectShow from "../SelectShow";
import Explanations from "../Explanation";
import Productoverview from "../Productoverview";
import Dealers from "../Dealers";
import About from "../About";
import History from "../History";
import Glossary from "../Glossary";
import ProductSpecs from "../ProductSpecs";
import CookiePolicy from "../CookiePolicy";
import Offers from "../Offers/Offers";

const RoutesMain = ({showItems, setShowItems, clickHandlerShowItem, clientCountry, clientVat}) => {
  return (
    <Routes>
      <Route path="/" element={<HomeArticle />} />

      <Route
        path="/technical"
        element={
          <>
            <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} />
            <Explanations showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} />
          </>
        }
      />

      <Route
        exact
        path="/products"
        element={
          <>
            <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} />
            <Productoverview showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} />
          </>
        }
      />

      <Route path="/dealers" element={<Dealers clientCountry={clientCountry} />} />

      <Route path="/about" element={<About />} />

      <Route path="/history" element={<History />} />

      <Route
        path="/glossary"
        element={
          <>
            <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} />
            <Glossary showItems={showItems} setShowItems={setShowItems} clickHandlerShowItem={clickHandlerShowItem} />
          </>
        }
      />

      <Route path="/products/:url" element={<ProductSpecs clientCountry={clientCountry} clientVat={clientVat} />} />

      <Route path="/cookie-policy" element={<CookiePolicy />} />

      <Route path="/offers" element={<Offers clientCountry={clientCountry} clientVat={clientVat} />} />
    </Routes>
  );
};

export default RoutesMain;
