import React, {useState, useEffect} from "react"
import {Helmet} from "react-helmet"
import axios from 'axios' 
import DataVat from "./components/DataVat"
import zwijnbos from "./assets/zwijn-in-bos.jpg";
import Navbar from './components/Nav/Navbar.jsx';
import './App.css';
import Footer from "./components/Footer"
import "./locales/i18n";
import { useTranslation } from "react-i18next";
import RoutesHeader from "./components/Routes/RoutesHeader";
import RoutesMain from "./components/Routes/RoutesMain";
import BackToTopButton from "./components/BackToTopButton";
import CookieBanner from "./components/CookieBanner";


function App({open}) {
  const [destination, setDestination] = useState("")
  const [showItems, setShowItems] = useState("thermal")
  const [showCountry, setShowCountry] = useState("Nederland")
  const [clientCountry, setClientCountry] = useState('Netherlands')
  const [clientVat, setClientVat] = useState(21)
  const [showCookieBanner, setShowCookieBanner] = useState(true)

  const { t, i18n } = useTranslation();

  const clickHandlerShowCountry = value =>{
    setClientCountry(value)
  }
  const clickHandlerShowItem = value =>{
    setShowItems(value)
  }
 
  const clickHandlerDestination = value =>{
    setDestination(value)
  }




  //creating function to load ip address from the API
  const getClientData = async () => {
    try{ const res = await axios.get('https://geolocation-db.com/json/')


    const resfound = DataVat.find(item => item.country === res.data.country_name)
    if (resfound != null) setClientCountry(resfound.country)
    createClientVat(resfound)}
    catch(_error){setClientCountry("Netherlands")}
  }

  const clickHandlerCookieBanner = () =>{
    setShowCookieBanner(false)
    localStorage.setItem("cookieMessageOO", "closed")
  }
  const cookieMessageClosed = localStorage.getItem("cookieMessageOO")


  const createClientVat = (resfound) =>{
    setClientVat(DataVat
      .find(item => item.country === resfound.country).amount
      )
  }

  useEffect( () => {
    //passing getData method to the lifecycle method
    getClientData()
    
  }, [])


  const checkEndDate = (date) => new Date(date) >= new Date().setHours(0, 0, 0, 0);

 // make default title meta tag in Helmet get translatable content
 const defaultTitle = {
  en: "OutdoorOptics - Quality Night Vision, Thermal Imaging and Digital devices",
  nl: "OutdoorOptics - Hoge kwaliteit nachtzicht kijkers, warmtebeeld kijkers en digitale kijkers",
  de: "OutdoorOptics - Hochwertige Nachtsichtgeräte, Wärmebildgeräte und digitale Geräte",
};

  return (
    <div className="App">

      <Helmet titleTemplate={"%s | " + defaultTitle[i18n.language]} defaultTitle={defaultTitle[i18n.language]}>
        <meta name="description" content="OutdoorOptics is a long-time supplier of nightvision, thermal and digital devices." />
      </Helmet>

      {/* {destination ?  */}
      <div>
      {/* <Choise clickHandlerDestination={clickHandlerDestination} destination={destination}/> */}
      <header
        className="App-header"
        style={{
          backgroundImage: `url(${zwijnbos})`,
        }}
      >
        <Navbar
          clientCountry={clientCountry}
          showItems={showItems}
          clickHandlerShowItem={clickHandlerShowItem}
        />

        <RoutesHeader showItems={showItems} checkEndDate={checkEndDate} />
      </header>

      <main>
        <RoutesMain showItems={showItems} setShowItems={setShowItems} clickHandlerShowItem={clickHandlerShowItem} clientCountry={clientCountry} clientVat={clientVat} />
        <BackToTopButton />
        {cookieMessageClosed ? null :
            <CookieBanner clickHandlerCookieBanner={clickHandlerCookieBanner} />
        }
      </main>

      <Footer />

      </div>
    </div>
  );
}

export default App;
