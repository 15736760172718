import React from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataOffers from "./DataOffers";
import OfferCard from "./OfferCard";

const Offers = ({ clientCountry, clientVat }) => {
  const { t } = useTranslation();

  const offers = DataOffers.map((offer) => (
    <OfferCard key={offer.id} offer={offer} clientCountry={clientCountry} clientVat={clientVat} />
    ))

  return (
    <StyledOffer>
      <Helmet>
        <title>{t("offers_page_heading")}</title>
        <meta
          name="description"
          content="Special offers of OutdoorOptics products."
        />
      </Helmet>

      <article>
        <h1>{t("offers_page_heading")}</h1>
      </article>

      <section className="cards">
        {/* Cards with offers mapped from DataOffers */}

        {offers}

      </section>
    </StyledOffer>
  );
};

const StyledOffer = styled.div`
  margin: 60px auto 120px auto;
  max-width: 90vw;
  font-size: 20px;
  @media (min-width: 768px) {
    max-width: 80vw;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  article {
    margin-bottom: 40px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
  }

`;

export default Offers;
