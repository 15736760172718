const DataVat = [
	{
		id: 1,
		country: "Austria",
		amount: 20,	   
	},
	{
		id: 2,
		country: "Belgium",
		amount: 21,	   
	},
	{
		id: 3,
		country: "Bulgaria",
		amount: 20,	   
	},
	{
		id: 4,
		country: "Cyprus",
		amount: 19,	   
	},
	{
		id: 5,
		country: "Czech republic",
		amount: 21,	   
	},
	{
		id: 6,
		country: "Denmark",
		amount: 25,	   
	},
	{
		id: 7,
		country: "Estonia",
		amount: 20,	   
	},
	{
		id: 8,
		country: "Finland",
		amount: 24,	   
	},
	{
		id: 9,
		country: "France",
		amount: 20,	   
	},
	{
		id: 10,
		country: "Germany",
		amount: 19,	   
	},
	{
		id: 11,
		country: "Greece",
		amount: 24,	   
	},
	{
		id: 12,
		country: "Hungary",
		amount: 27,	   
	},
	{
		id: 13,
		country: "Ireland",
		amount: 23,	   
	},
	{
		id: 14,
		country: "Italy",
		amount: 22,	   
	},
	{
		id: 15,
		country: "Croatia",
		amount: 25,	   
	},
	{
		id: 16,
		country: "Latvia",
		amount: 21,	   
	},
	{
		id: 17,
		country: "Lithuania",
		amount: 21,	   
	},
	{
		id: 18,
		country: "Luxembourg",
		amount: 17,	   
	},
	{
		id: 19,
		country: "Malta",
		amount: 18,	   
	},
	{
		id: 20,
		country: "Netherlands",
		amount: 21,	   
	},
	{
		id: 21,
		country: "Poland",
		amount: 23,	   
	},
	{
		id: 22,
		country: "Portugal",
		amount: 23,	   
	},
	{
		id: 23,
		country: "Romania",
		amount: 19,	   
	},
	{
		id: 24,
		country: "Slovakia",
		amount: 20,	   
	},
	{
		id: 25,
		country: "Portugal",
		amount: 23,	   
	},
	{
		id: 26,
		country: "Romania",
		amount: 19,	   
	},
	{
		id: 27,
		country: "Slovakia",
		amount: 20,	   
	},
	{
		id: 28,
		country: "Sweden",
		amount: 25,	   
	},
	{
		id: 29,
		country: "United kingdom",
		amount: 19,	   
	},
	
];
export default DataVat;