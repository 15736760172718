import React from 'react';
import DataAboutus from './DataAboutus.js'
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import nachtzichtZwijnen from './../assets/nachtzicht-zwijnen-hemera-groen.png'
import digital from "./../assets/wild-zwijn-digitaal.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import {
    Link
} from "react-router-dom";
import VideoElement from './VideoElement.js';



const Explanations = ({ showItems, clickHandlerShowItem }) => {
    const { t, i18n } = useTranslation();
    return (
      <StyledExplanations>
        <Helmet>
          <title>{t("menu_techniek")}</title>
          <meta name="description" content="Explanation of thermal imaging, night vision and digital" />
        </Helmet>

        {showItems === "thermal" && (
          <React.Fragment>
            <br />
            <div className="content">
              <p>{t("tech_warm_1")}</p>
              <p>{t("tech_warm_2")}</p>
              <p>{t("tech_warm_3")}</p>
              <p>{t("tech_warm_4")}</p>
              <p>{t("tech_warm_5")}</p>
              <p>{t("tech_warm_6")}</p>
              <p>{t("tech_warm_7")}</p>
            </div>

            <div className="coloroverview">
              <ul>
                {DataAboutus.map((colormodi) => (
                  <li key={colormodi.id}>
                    <div className="content">
                      <h2>{colormodi.title[i18n.language]}</h2>
                      <p>{colormodi.desc[i18n.language]}</p>
                    </div>
                    <VideoElement video={"/videos/" + colormodi.video} />
                  </li>
                ))}
              </ul>
            </div>
            <Link to="/products">{t("tech_warm_button")}</Link>
            <br />
          </React.Fragment>
        )}
        {showItems === "night" && (
          <React.Fragment>
            <br />
            <div className="nachtzicht">
              <div className="content">
                <p>{t("tech_nacht_1")} </p>
                <p>{t("tech_nacht_2")} </p>
                <p>{t("tech_nacht_3")} </p>
                <p>{t("tech_nacht_4")} </p>
                <p>{t("tech_nacht_5")} </p>
                <p>{t("tech_nacht_6")} </p>
                <p>{t("tech_nacht_7")} </p>
                <p>{t("tech_nacht_8")} </p>
              </div>
              <div className="preview">
                  <img src={nachtzichtZwijnen} alt="Wilde zwijnen met een nachtzicht kijker." />
              </div>              
            </div>
            <Link to="/products">{t("tech_nacht_button")}</Link>
          </React.Fragment>
        )}
        {showItems === "digital" && (
          <React.Fragment>
            <br />
            <div className="nachtzicht">
              <div className="content">
                <p>{t("tech_digi_1")} </p>
                <p>{t("tech_digi_2")} </p>
                <p>{t("tech_digi_3")} </p>
              </div>
              <div className="preview">
                  <img src={digital} alt="Wilde zwijnen met digitale kijker" />
              </div>
            </div>
            <Link to="/products">{t("tech_digi_button")}</Link>
          </React.Fragment>
        )}
      </StyledExplanations>
    );
}
const StyledExplanations = styled.div.attrs({
  className: "testClass",
})`
  margin: 10px auto 0 auto;
  max-width: 90vw;
  font-size: 20px;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 60vw;
  }

  p {
    max-width: 100%;
    margin-bottom: 20px;
  }
  p:last-of-type {
    margin-bottom: 50px;
  }
  .coloroverview ul li {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }
  .coloroverview li .content {
    width: 500px;
    margin-right: 60px;
  }
  .coloroverview div h2 {
    border-bottom: 2px solid var(--brand-accent-color);
    display: block;
    font-size: 22px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .coloroverview {
    border-left: 4px solid var(--brand-accent-color);
    padding-left: 20px;
    margin-left: 50px;
  }
  a {
    color: var(--link);
    text-decoration: none;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
  }
  a:hover {
    background-color: var(--link-hover);
    border: 1px solid var(--link-hover);
  }
  
  .nachtzicht,
  .thermal,
  .digital {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
  
  .preview img {
    border-radius: 50%;
    width: 350px;
  }

  .preview, .video-element {
    @media (max-width: 1200px) {
      margin: 0 auto;
    }
  }
`;
export default Explanations