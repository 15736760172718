const DataOffers = [
  {
    id: 1,
    title: {
      en: "Dedal D-480/100",
      nl: "Dedal D-480/100",
      de: "Dedal D-480/100",
    },
    desc: {
      en: "Night vision rifle scope. Photonis tube with FOM 1600!",
      nl: "Nachtzicht richtkijker. Photonis buis met FOM 1600!",
      de: "Nachtsicht-Zielfernrohr. Photonis Rohr mit FOM 1600!",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1419.01,
    img: "D-480.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

  /*
  // remove from offers (DTPWEB-745)
  {
    id: 2,
    title: {
      en: "Dedal D-480/165",
      nl: "Dedal D-480/165",
      de: "Dedal D-480/165",
    },
    desc: {
      en: "Night vision rifle scope. Photonis tube with FOM 1600!",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1342.975,
    img: "D-480.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
*/

  {
    id: 3,
    title: {
      en: "Dedal D-542",
      nl: "Dedal D-542",
      de: "Dedal D-542",
    },
    desc: {
      en: "Night vision front attachment. Photonis tube with FOM 1600!",
      nl: "Voorzet voor nachtzicht. Photonis buis met FOM 1600!",
      de: "Nachtsichtvorsatz. Photonis Rohr mit FOM 1600!",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1566.115,
    img: "D-542.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

/*  
// Verkocht
  {
    id: 27,
    title: {
      en: "Dedal Tracker T2 380",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image goggle",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2475.21,
    img: "Dedal-Tracker-T2-380.png",
    number: "172137",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
  */
 /* Niet compleet (geen core), verwijderen
  {
    id: 4,
    title: {
      en: "Infiray SL35",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1260.33,
    img: "SL35.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
  */
  /* Verkocht 20-3
  {
    id: 5,
    title: {
      en: "Infiray SL50",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1425.62,
    img: "SL50.png",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
  */
 /* Verkocht 06-04-2023
  {
    id: 6,
    title: {
      en: "Infiray SH50",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2020.66,
    img: "SH50.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/
// Verkocht CAL 19
/*
  {
    id: 7,
    title: {
      en: "InfiRay CAL 19",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1007.20,
    img: "CAL-19.jpg",
    number: "KA1011",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

  // E6+ V3 tot nader order verwijderen
/*
  {
    id: 8,
    title: {
      en: "InfiRay E6+ V3.0",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 789.89,
    img: "E6Plus-V3.png",
    number: "2261A-KE1544",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/ 
/*
// Verkocht 24-4-2023
  {
    id: 9,
    title: {
      en: "PARD NV007",
      nl: "PARD NV007",
      de: "PARD NV007",
    },
    desc: {
      en: "Night vision scope + 42mm Adapter + 48mm Adapter",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 164.46,
    img: "PARD-NV007-plus-adapter.png",
    number: "62220329",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
*/

// Verkocht ZH38 en ZH50
/*
  {
    id: 10,
    title: {
      en: "InfiRay ZH38",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2090.59,
    img: "ZH38.png",
    number: "2E20A-KL1293",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },

  {
    id: 11,
    title: {
      en: "InfiRay ZH50",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2313.49,
    img: "ZH50.png",
    number: "2E31A-LC1013",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht RH50
/*
  {
    id: 12,
    title: {
      en: "InfiRay RH50",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 3284.29,
    img: "RH50.png",
    number: "2911A-JH1005",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht GL35R
/*
  {
    id: 13,
    title: {
      en: "InfiRay GL35R",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2385.10,
    img: "GL35R.png",
    number: "2H11A-LC2205",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht TH35
/*
  {
    id: 14,
    title: {
      en: "InfiRay TH35",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image rifle scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1400.00,
    img: "TH35.png",
    number: "2D31A-KK1007",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht Guide IR510 Nano N1
/*
    {
    id: 15,
    title: {
      en: "Guide IR510 Nano N1",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 828.57,
    img: "Guide-IR510-Nano-N1.png",
    number: "ZG01A1900832",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

   // E6+ V3 tot nader order verwijderen
/*
  {
    id: 16,
    title: {
      en: "InfiRay E6+ V3.0",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2345.96,
    img: "E6Plus-V3.png",
    number: "HG1006-009566",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
 */
 
  // Tijdelijk van site
  /*
  {
    id: 17,
    title: {
      en: "InfiRay FL25R",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2121.43,
    img: "FL25R.png",
    number: "2615A-KE1187",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht InfiRay E2n V2
/*
  {
    id: 21,
    title: {
      en: "InfiRay E2n V2",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 703.79,
    img: "E2n-V2.png",
    number: "IE1770",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

// Verkocht E6Pro V3
/*
  {
    id: 22,
    title: {
      en: "InfiRay E6Pro V3",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 2545.71,
    img: "E6Pro-V3.png",
    number: "2274A-JJ1422",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

  {
    id: 23,
    title: {
      en: "Infratech/SOT X4 Pro",
      nl: "Infratech/SOT X4 Pro",
      de: "Infratech/SOT X4 Pro",
    },
    desc: {
      en: "Night vision rifle scope. XX1441BT tube. Very high FOM value of 1836! ",
      nl: "Nachtzicht richtkijker. XX1441BT buis. Zeer hoge FOM-waarde van 1836!",
      de: "Nachtsicht-Zielfernrohr. XX1441BT Rohr. Sehr hoher FOM-Wert von 1836!",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 822.31,
    img: "COT-X4-Pro.png",
    number: "60041",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
  {
    id: 24,
    title: {
      en: "Thermoteknix thermal module",
      nl: "Thermoteknix thermal module",
      de: "Thermoteknix thermal module",
    },
    desc: {
      en: "Thermal component",
      nl: "Thermische component",
      de: "Thermische Komponente",
    },
    remark: {
      en: "",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 822.31,
    img: "microCam.png",
    number: "20207",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
    /* Verkocht 20-3
  {
    id: 25,
    title: {
      en: "Dali S240-M",
      nl: "",
      de: "",
    },
    desc: {
      en: "Thermal image observation",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 326.45,
    img: "S240-M.png",
    number: "22S240Y00773",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
  */
 /*
  {
    id: 26,
    title: {
      en: "FLIR/Armasight CO-X Clip-On",
      nl: "FLIR/Armasight CO-X Clip-On",
      de: "FLIR/Armasight CO-X Clip-On",
    },
    desc: {
      en: "Night vision Clip-On + CO-X hardcase, black",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1404.96,
    img: "CO-X.png",
    number: "160014/04",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
  */
  {
    id: 28,
    title: {
      en: "Dedal-382 T2 Ranger 1",
      nl: "Dedal-382 T2 Ranger 1",
      de: "Dedal-382 T2 Ranger 1",
    },
    desc: {
      en: "Thermal image scope",
      nl: "Warmtebeeldkijker",
      de: "Wärmebildgerät",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1648.76,
    img: "Dedal-382-T2-Ranger.png",
    number: "162640",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

/*
  {
    id: 29,
    title: {
      en: "Dedal D-370 Standard+",
      nl: "",
      de: "",
    },
    desc: {
      en: "Night vision scope",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1152.89,
    img: "D-370.png",
    number: "71104",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/
  /* Verkocht D-370
  {
    id: 36,
    title: {
      en: "Dedal D-370 Standard+",
      nl: "",
      de: "",
    },
    desc: {
      en: "Night vision scope. Resolution 69 lp/mm, with black spot",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 904.96,
    img: "D-370.png",
    number: "71104",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/
  {
    id: 30,
    title: {
      en: "Flir HS307",
      nl: "Flir HS307",
      de: "Flir HS307",
    },
    desc: {
      en: "Thermal imaging camera",
      nl: "Warmtebeeldcamera",
      de: "Wärmebildkamera",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 657.024,
    img: "Flir-HS307.png",
    number: "T09844",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
  {
    id: 51,
    title: {
      en: "Flir HS307",
      nl: "Flir HS307",
      de: "Flir HS307",
    },
    desc: {
      en: "Thermal imaging camera + Flir Carrying Pouch + Flir Hot shoe, Charging/Video Out",
      nl: "Warmtebeeldcamera + Flir draagtas + Flir Hot shoe, opladen/video uit",
      de: "Wärmebildkamera + Flir Tragetasche + Flir Hot shoe, Aufladen/Videoausgang",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 739.67,
    img: "Flir-HS307-pouch-hot-shoe.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
  {
    id: 31,
    title: {
      en: "Flir EVS-3 - 35mm",
      nl: "Flir EVS-3 - 35mm",
      de: "Flir EVS-3 - 35mm",
    },
    desc: {
      en: "Compact thermal imager",
      nl: "Compacte warmtebeeldcamera",
      de: "Kompakte Wärmebildkamera",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 739.67,
    img: "Flir-EVS-3.png",
    number: "21280",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

/*
  {
    id: 32,
    title: {
      en: "Lens for InfiRay Eye E3+",
      nl: "",
      de: "",
    },
    desc: {
      en: "",
      nl: "",
      de: "",
    },
    remark: {
      en: "",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 1,
    img: "lens-E3plus.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/
  {
    id: 33,
    title: {
      en: "Drone FIMI X8 Mini",
      nl: "Drone FIMI X8 Mini",
      de: "Drone FIMI X8 Mini",
    },
    desc: {
      en: "250g-class ultralight drone",
      nl: "250g-klasse ultralichte drone",
      de: "Ultraleicht-Drohne der 250g-Klasse",
    },
    remark: {
      en: "Demo device, signs of use",
      nl: "Demo apparaat, gebruikssporen",
      de: "Demo-Gerät, Gebrauchsspuren",
    },
    price_offer_ex_vat: 205.785,
    img: "FIMI-X8-Mini.png",
    number: "09-9997-03030",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

    {
    id: 34,
    title: {
      en: "LG-31/336-55",
      nl: "LG-31/336-55",
      de: "LG-31/336-55",
    },
    desc: {
      en: "Thermal Imaging goggle",
      nl: "Thermal Imaging goggle",
      de: "Thermal Imaging goggle",
    },
    remark: {
      en: "Demo device, not used",
      nl: "Demo apparaat, niet gebruikt",
      de: "Demo-Gerät, nicht verwendet",
    },
    price_offer_ex_vat: 1028.925,
    img: "LG-31-55.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },

  /*
  // remove LG-31 light damage, DTPWEB-747
  {
    id: 35,
    title: {
      en: "LG-31/336-55",
      nl: "LG-31/336-55",
      de: "LG-31/336-55",
    },
    desc: {
      en: "Thermal Imaging goggle",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, light damage",
      nl: "Demo apparaat, lichte beschadiging",
      de: "Demo-Gerät, leichte Beschädigung",
    },
    price_offer_ex_vat: 1400.826,
    img: "LG-31-55.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "Bestel",
      de: "Bestellen",
    },
  },
*/

  /*
  // Verkocht
  {
    id: 37,
    title: {
      en: "Laserworks LE-032 Mini Rangefinder",
      nl: "",
      de: "",
    },
    desc: {
      en: "Laser rangefinder",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 123.97,
    img: "LE-032-Mini-Rangefinder.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
  */
 /*
 // Verkocht
  {
    id: 38,
    title: {
      en: "IR Illuminator NL85040DW",
      nl: "",
      de: "",
    },
    desc: {
      en: "Laser light",
      nl: "",
      de: "",
    },
    remark: {
      en: "Demo device, not used",
      nl: "",
      de: "",
    },
    price_offer_ex_vat: 81.82,
    img: "IR-Illuminator-NL85040DW.png",
    number: "",
    button_text: {
      en: "Order",
      nl: "",
      de: "",
    },
  },
*/

{
  id: 39,
  title: {
    en: "Phönix Focus",
    nl: "Phönix Focus",
    de: "Phönix Focus",
  },
  desc: {
    en: "IR illuminator",
    nl: "IR illuminator",
    de: "IR illuminator",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 106.61,
  img: "Phonix-Focus.png",
  number: "DJ-J1854",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},
{
  id: 40,
  title: {
    en: "Flir Lens 2x Extender H-series",
    nl: "Flir Lens 2x Extender H-series",
    de: "Flir Lens 2x Extender H-series",
  },
  desc: {
    en: "Lens for Flir H-series",
    nl: "Lens voor Flir H-serie",
    de: "Linse für Flir H-Serie",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 235.537,
  img: "Flir-Lens-2x-Extender-H-series.png",
  number: "03-0001-00109",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

/*
{
  id: 41,
  title: {
    en: "XX0041H (43 mm)",
    nl: "",
    de: "",
  },
  desc: {
    en: "Night Vision tube, Cat C",
    nl: "",
    de: "",
  },
  remark: {
    en: "Demo device, not used",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 413.22,
  img: "Flir-Lens-2x-Extender-H-series.png",
  number: "8240272",
  button_text: {
    en: "Order",
    nl: "",
    de: "",
  },
},
*/

{
  id: 42,
  title: {
    en: "Ophir Lens 50mm",
    nl: "Ophir Lens 50mm",
    de: "Ophir Linse 50mm",
  },
  desc: {
    en: "F/1.2 FIX 8-12um Ath HC",
    nl: "F/1.2 FIX 8-12um Ath HC",
    de: "F/1.2 FIX 8-12um Ath HC",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 326.45,
  img: "Ophir-Lens-50mm.png",
  number: "15056016",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 43,
  title: {
    en: "Thermoteknix MicroCAM shutter-less core 384 h (50mK NO lens)",
    nl: "Thermoteknix MicroCAM shutterloze kern 384 h (50mK GEEN lens)",
    de: "Thermoteknix MicroCAM verschlussloser Kern 384 h (50mK KEINE Objektiv)",
  },
  desc: {
    en: "NO LENS, only MicroCam core",
    nl: "GEEN LENS, alleen MicroCam kern",
    de: "KEINE LINSEN, nur MicroCam-Kern",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 2061.98,
  img: "MicroCAM-core-384-shutter-less.png",
  number: "20207",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 44,
  title: {
    en: "MAK Adapter 2460-0040",
    nl: "MAK Adapter 2460-0040",
    de: "MAK Adapter 2460-0040",
  },
  desc: {
    en: "Adapter ZM-similar design (e.g. Dedal)",
    nl: "Adapter ZM-vergelijkbaar ontwerp (bijv. Dedal)",
    de: "Adapter ZM-ähnliche Ausführung (z.B. Dedal)",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 51.239,
  img: "MAK-Adapter-2460-0040.png",
  number: "09-0009-00038",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 45,
  title: {
    en: "MAK mount 5092 0000",
    nl: "MAK mount 5092 0000",
    de: "MAK mount 5092 0000",
  },
  desc: {
    en: "Basic assembly for Blaser system",
    nl: "Basisassemblage voor Blaser-systeem",
    de: "Grundausstattung für Blaser-System",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 155.37,
  img: "MAK-mount-5092-0000.png",
  number: "09-0009-00297",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 46,
  title: {
    en: "MAK Adapter 5750-0040", // zelfde als 2460
    nl: "MAK Adapter 5750-0040",
    de: "MAK Adapter 5750-0040",
  },
  desc: {
    en: "Adapter ZM-similar design (e.g. Dedal)",
    nl: "Adapter ZM-vergelijkbaar ontwerp (bijv. Dedal)",
    de: "Adapter ZM-ähnliche Ausführung (z.B. Dedal)",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 51.239,
  img: "MAK-Adapter-5750-0040.png",
  number: "",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 47,
  title: {
    en: "MAKuick Basis 50572-0000",
    nl: "MAKuick Basis 50572-0000",
    de: "MAKuick Basis 50572-0000",
  },
  desc: {
    en: "MAKuick 1 pcs base only",
    nl: "MAKuick 1 stuk alleen basis",
    de: "MAKuick 1 Stück nur Basis",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 132.23,
  img: "MAKuick-Basis-50572-0000.png",
  number: "09-0009-02114",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 48,
  title: {
    en: "MAKmilmont single monoblock base 5750-0000",
    nl: "MAKmilmont single monoblock base 5750-0000",
    de: "MAKmilmont single monoblock base 5750-0000",
  },
  desc: {
    en: "Quick-release single monoblock base",
    nl: "Snelkoppeling enkelvoudige monoblock basis",
    de: "Einfacher Monoblock-Sockel mit Schnellverschluss",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 158.677,
  img: "MAKmilmont-5750-0000.png",
  number: "09-0009-03591",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},


{
  id: 49,
  title: {
    en: "LS-42",
    nl: "LS-42",
    de: "LS-42",
  },
  desc: {
    en: "Night vision rifle scope. Photonis tube (XX0041G), FOM 1381",
    nl: "Nachtzicht richtkijker. Photonis buis (XX0041G), FOM 1381",
    de: "Nachtsicht-Zielfernrohr. Photonis Rohr (XX0041G), FOM 1381",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 1070.25,
  img: "LS-42.png",
  number: "74740138",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 50,
  title: {
    en: "Flir Hot shoe Charging/Video Out",
    nl: "Flir Hot shoe opladen/video uit",
    de: "Flir Hot shoe Aufladen/Videoausgang",
  },
  desc: {
    en: "For HS series",
    nl: "Voor HS-serie",
    de: "Für die HS-Serie",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 23.966,
  img: "flir-hot-shoe.png",
  number: "02-0009-00154",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 52,
  title: {
    en: "Flir carrying pouch",
    nl: "Flir draagtas",
    de: "Flir Tragetasche",
  },
  desc: {
    en: "For HS-X/HS-XR series",
    nl: "Voor de HS-X/HS-XR serie",
    de: "Für die HS-X/HS-XR Serie",
  },
  remark: {
    en: "Demo device, not used",
    nl: "Demo apparaat, niet gebruikt",
    de: "Demo-Gerät, nicht verwendet",
  },
  price_offer_ex_vat: 23.966,
  img: "Flir-carrying-pouch.png",
  number: "09-0200-00111",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

/* 
// Verkocht
{
  id: 53,
  title: {
    en: "Night Fire 4 Turbo-860",
    nl: "",
    de: "",
  },
  desc: {
    en: "IR Illuminator Laser light",
    nl: "",
    de: "",
  },
  remark: {
    en: "Demo device, not used",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 119.834,
  img: "Night-Fire-4-Turbo-860.png",
  number: "01-0030-1791",
  button_text: {
    en: "Order",
    nl: "",
    de: "",
  },
},
*/

{
  id: 53,
  title: {
    en: "Nivex Digital Plus",
    nl: "Nivex Digital Plus",
    de: "Nivex Digital Plus",
  },
  desc: {
    en: "Digital night vision attachment",
    nl: "Digitale nachtzicht voorzet",
    de: "Digitaler Nachtsichtvorsatz",
  },
  remark: {
    en: "Demo device with signs of use",
    nl: "Demo apparaat met gebruikssporen",
    de: "Demo-Gerät mit Gebrauchsspuren",
  },
  price_offer_ex_vat: 739.67,
  img: "Nivex-Digital-Plus.png",
  number: "05-0006-02689",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},
/* Verkocht 24-4-23
{
  id: 54,
  title: {
    en: "Sealed Reflex Sight, Red Dot",
    nl: "Sealed Reflex Sight, Red Dot",
    de: "Sealed Reflex Sight, Red Dot",
  },
  desc: {
    en: "Sealed Reflex Sight Red Dot SRS01",
    nl: "",
    de: "",
  },
  remark: {
    en: "Demo device, slight sign of use",
    nl: "Demo apparaat, lichte tekenen van gebruik",
    de: "Demo-Gerät, leichte Gebrauchsspuren",
  },
  price_offer_ex_vat: 326.45,
  img: "Sealed_Reflex_Sight_Red_Dot_SRS01.png",
  number: "09-0009-00674",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},
*/
/*
// Remove from offers (DTPWEB-738)
{
  id: 55,
  title: {
    en: "Photonis image intensifier tube 43 mm",
    nl: "Photonis image intensifier tube 43 mm",
    de: "Photonis image intensifier tube 43 mm",
  },
  desc: {
    en: "Photonis image intensifier tube 43 mm FAT ANVIS",
    nl: "",
    de: "",
  },
  remark: {
    en: "New",
    nl: "Nieuw",
    de: "Neu",
  },
  price_offer_ex_vat: 826.45,
  img: "Photonis-Bildverstaerkerroehre-43mm.png",
  number: "8240272",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},
*/

{
  id: 56,
  title: {
    en: "Blaser Sattelmontage Prisma",
    nl: "Blaser Sattelmontage Prisma",
    de: "Blaser Sattelmontage Prisma",
  },
  desc: {
    en: "Original Blaser Saddle mount for prism rail",
    nl: "Originele Blaser Zadelmontage voor prismarail",
    de: "Original Blaser Sattelmontage für Prismenschiene",
  },
  remark: {
    en: "New",
    nl: "Nieuw",
    de: "Neu",
  },
  price_offer_ex_vat: 243.80,
  img: "Blaser-Sattelmontage-Prisma.png",
  number: "09-0009-01202",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 57,
  title: {
    en: "Weaver/picatinny montage",
    nl: "Weaver/picatinny montage",
    de: "Weaver/picatinny montage",
  },
  desc: {
    en: "Weaver/picatinny montage for Infiray SL35-50/SH50-75 Serie",
    nl: "Weaver/picatinny montage voor Infiray SL35-50/SH50-75 Serie",
    de: "Weaver/Picatinny-Montage für Infiray SL35-50/SH50-75 Serie",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 131.404,
  img: "Weaver-Picatinny.png",
  number: "09-0009-02176",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 58,
  title: {
    en: "Blaser montage",
    nl: "Blaser montage",
    de: "Blaser montage",
  },
  desc: {
    en: "Blaser montage for Infiray SL35-50/SH50-75 Serie",
    nl: "Blaser montage voor Infiray SL35-50/SH50-75 serie",
    de: "Blaser Montage für Infiray SL35-50/SH50-75 Serie",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 131.404,
  img: "Blaser-montage.png",
  number: "09-0009-02177",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

{
  id: 59,
  title: {
    en: "Rusan Adapter AD-545-x",
    nl: "Rusan Adapter AD-545-x",
    de: "Rusan Adapter AD-545-x",
  },
  desc: {
    en: "Universal adapter for attachments, available in almost all sizes.",
    nl: "Universele adapter voor voorzetten, verkrijgbaar in bijna alle maten.",
    de: "Universaladapter für Vorsatzgeräte, erhältlich in fast allen Größen.",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 142.15,
  img: "Rusan-Adapter-AD-545-x.png",
  number: "01-0009-xxxxx",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

/*
// verkocht, DTPWEB-746
{
  id: 60,
  title: {
    en: "Infiray E6+",
    nl: "",
    de: "",
  },
  desc: {
    en: "Thermal imaging monocular 35mm lens",
    nl: "",
    de: "",
  },
  remark: {
    en: "Demo device, signs of use",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 822.31,
  img: "Infiray-E6Plus.png",
  number: "02-0006-01761",
  button_text: {
    en: "Order",
    nl: "",
    de: "",
  },
},
*/
/*
{
  id: 61,
  title: {
    en: "InfiRay Finder FL25R",
    nl: "InfiRay Finder FL25R",
    de: "InfiRay Finder FL25R",
  },
  desc: {
    en: "Thermal imaging scope with LRF",
    nl: "Warmtebeeldkijker met LRF",
    de: "Wärmebildgerät mit LRF",
  },
  remark: {
    en: "Demo device, not used",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 987.60,
  img: "Finder-FL25R.png",
  number: "2611A-KB1264",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},
*/
{
  id: 62,
  title: {
    en: "LM-11",
    nl: "LM-11",
    de: "LM-11",
  },
  desc: {
    en: "55 mm lens for observation at large distances. Thermal imaging monocular. ",
    nl: "55 mm lens voor observatie op grote afstanden. Warmtebeeldmonoculair.",
    de: "55-mm-Linse für die Beobachtung auf große Entfernungen. Wärmebild-Monokular.",
  },
  remark: {
    en: "Demo device, not used",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 979.34,
  img: "LM-11.png",
  number: "02-0002-00950",
  button_text: {
    en: "Order",
    nl: "Bestel",
    de: "Bestellen",
  },
},

/*
// Verkocht
{
  id: 55,
  title: {
    en: "PVS-14 with EPM 221G tube",
    nl: "",
    de: "",
  },
  desc: {
    en: "Night vision monocular",
    nl: "",
    de: "",
  },
  remark: {
    en: "",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: 1235.54,
  img: "PVS-14.png",
  number: "",
  button_text: {
    en: "Order",
    nl: "",
    de: "",
  },
},
*/

// Template
  /*
{
  id: X,
  title: {
    en: "",
    nl: "",
    de: "",
  },
  desc: {
    en: "",
    nl: "",
    de: "",
  },
  remark: {
    en: "Demo device, not used",
    nl: "",
    de: "",
  },
  price_offer_ex_vat: ,
  img: "",
  number: "",
  button_text: {
    en: "Order",
    nl: "",
    de: "",
  },
},

*/

 
];
export default DataOffers;
