export const TRANSLATIONS_NL = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Producten",
    menu_producten_nacht: "Nachtzicht",
    menu_producten_warm: "Warmtebeeld",
    menu_producten_acces: "Accessoires",
    menu_producten_digi: "Digitaal",
    menu_producten_overig: "Meer producten bekijken",
    menu_techniek: "Techniek",
    menu_over: "Over ons",
    menu_over_history: "Geschiedenis",
    menu_over_how: "Hoe wij werken",
    menu_over_vacatures: "Vacatures",
    menu_over_events: "Evenementen",
    menu_service: "Service",
    menu_offers: "Speciale aanbiedingen",
    menu_woorden: "Woordenlijst",
    menu_cookieverklaring: "Cookieverklaring",
    menu_privacy: "Privacybeleid",
    footer_volg: "Volg ons op",
    footer_rights: "Alle rechten voorbehouden",
    footer_price: "Alle prijzen zijn adviesverkoopprijzen incl. BTW",
    home_title: "",
    home_copy: "",
    home_button: "Lees meer",
    home_article_title: "Kwaliteit in thermisch zicht",
    home_article_copy: "OutdoorOptics is van oudsher leverancier van nachtzicht-, thermische en digitale kijkers. Met onze 30 jaar ervaring testen wij voor onze klanten de apparaten die op de markt beschikbaar zijn en selecteren wij de kijkers die aan onze hoge eisen voldoen. Deze toestellen bieden wij op onze website aan u aan. Wij beschikken over een eigen interne serviceafdeling zodat u zeker bent van een volledige ondersteuning na aankoop van uw kijker.",
    home_article_button: "Ontdek onze modellen",
    tech_intro_title: "OutdoorOptics",
    tech_intro_copy: "Warmtebeeldkijkers zijn onmisbaar gebleken voor diegene die het nut van deze techniek hebben ervaren. Zowel overdag als ‘s nachts geven de kijkers een extra dimensie aan het kijken naar de natuur. Zodra er ergens een temperatuurverschil meetbaar is zal de camera dit zichtbaar maken.  Het is dan ook uitsluitend warmtestraling die de camera detecteert. Met restlichtversterkers en digitale kijkers wordt juist het nog aanwezige (zichtbare) licht in de nacht versterkt tot een beeld dat uitstekend bruikbaar is en de nacht bijna tot dag veranderd. Het beeld ligt daardoor veel dichterbij wat we gewend zijn. ",
    tech_nacht_1: "Het haarscherpe beeld van onze nachtzicht kijkers stelt u in staat na de schemering en bij zwak maanlicht de meeste dieren en wild te observeren en aan te spreken.",
    tech_nacht_2: "De klassieke nachtzicht, ook genaamd helderheid- of restlichtversterker, gebruikt het bestaande licht dat nog aanwezig is in de nacht en versterkt dat tot een beeld dat uitstekend is te gebruiken. De nacht wordt bijna zo licht als de dag.",
    tech_nacht_3: "Daarvoor gebruikt de kijker maan- en sterrenlicht, reflecties door wolken van kunstmatige lichtbronnen en zelfs de aanwezige natuurlijke infraroodstraling.",
    tech_nacht_4: "Door dat laatste is het ook mogelijk om bij volledige duisternis met behulp van een Infraroodstraler toch beeld te kunnen krijgen.",
    tech_nacht_5: "Het hart van deze technologie is een kleine, analoge, restlichtversterkende buis die de weinige lichtdeeltjes via een ultragevoelig laag omzet in elektronen die onder hoge spanning verveelvoudigt worden en via een, door fosfor, oplichtend scherm voor ons oog zichtbaar gemaakt worden.",
    tech_nacht_6: "Voor het oplichtende fosfor wordt er gebruikt gemaakt van het bekende groen en een zwart/wit uitvoering, genaamd Onyx.",
    tech_nacht_7: "Aangezien het om een zeer gevoelig systeem gaat is het van belang om een kijker uitsluitend te gebruiken in het donker en de buis (overdag) niet bloot te stellen aan licht.",
    tech_nacht_8: "",
    tech_nacht_button: "Ga naar nachtzichtkijkers",
    tech_warm_1: "Met warmtebeeld blijven zelfs dieren die zich achter, niet te dicht, struikgewas bevinden niet verborgen. Ook vogels, zoals kraaien, die dicht tegen de stam zitten of vogels elders in bomen kunnen zo gevonden worden.",
    tech_warm_2: "Niet alleen in open velden maar ook in het bos is een warmtebeeld een onmisbaar gereedschap. Van muizen en kleine vogels zullen ook eekhoorns, marterachtigen, vossen tot de grotere hoefdieren niet aan uw aandacht ontsnappen. Hierdoor is zelfs op meerdere honderden meters zelfs in het bos warmte of beweging te detecteren. ",
    tech_warm_3: "Een belangrijk punt bij het gebruik van warmtebeeld zijn de weersomstandigheden. Als er weinig vocht in de lucht is zijn de prestaties beter dan tijdens een regenbui. De gebruiksafstand wordt daardoor beïnvloed. Het grote voordeel is dan wel dat als er een warm object zich in het beeldveld bevindt deze er duidelijk uitspringt.",
    tech_warm_4: "Het kost de gebruiker enige tijd om de beelden goed te interpreteren aangezien het beeld puur uit warmte is opgebouwd en veel voorwerpen en dieren er anders uitzien dan we gewend zijn. Daardoor blijft de gebruiker gefascineerd nieuwe dingen ontdekken. Door gebruik te maken van een klein deel van de infrarode straling om ons heen kunnen (ongekoelde) warmtebeeldcamera’s juist het temperatuurbereik van levende wezens zichtbaar maken. Dit gebeurt op een puur digitale wijze zodat er met beeldbewerking in de camera veel gedaan kan worden om de straling beter zichtbaar te maken voor de gebruiker. ",
    tech_warm_5: "Daarnaast kan het beeld digitaal vergroot worden en vaak ook opgenomen.",
    tech_warm_6: "Door de ongekoelde warmtebeeldcamera is de prijs en het gebruiksgemak van deze techniek is bereikbaar geworden voor veel doelgroepen. De nog gevoeligere en complexe gekoelde camera’s blijven door hun hoge prijs alleen beschikbaar voor overheden en sommige professionele gebruikers.",
    tech_warm_7: "De meeste modellen hebben 4 verschillende kleurmodi om bij vrijwel alle weersomstandigheden een goed beeld te hebben. ",
    tech_warm_button: "Ga naar warmtebeeldkijkers",
    tech_digi_1: "Naast de klassieke nachtzicht technologie heeft OutdoorOptics ook digitale restlichtversterkers.",
    tech_digi_2: "Dit zijn zeer gevoelige CCD/ camera sensoren die vergelijkbaar zijn met digitale camera sensoren en dus ook bruikbaar zijn overdag. Voor een goed zicht onder de donkerste omstandigheden is hierbij een infrarood straler nodig die OutdoorOptics ook in het assortiment heeft. ",
    tech_digi_3: "Het grote voordeel hiervan zijn de lagere kosten zodat deze techniek zeer bereikbaar is voor velen.",
    tech_digi_button: "Ga naar digitaal-kijkers",
    history_copy_title: "Over ons",
    history_copy_1: "OutdoorOptics is van oudsher leverancier van nachtzicht-, thermische en digitale kijkers. Met onze 30 jaar ervaring testen wij voor onze klanten de apparaten die op de markt beschikbaar zijn en selecteren wij de kijkers die aan onze hoge eisen voldoen.",
    history_copy_2: "Deze toestellen bieden wij op onze website aan u aan. Wij beschikken over een eigen interne serviceafdeling zodat u zeker bent van een volledige ondersteuning na aankoop van uw kijker.",
    history_copy_3: "",
    history_copy_4: "",
    work_1_title: "Stofvrij",
    work_1_copy: "De belangrijkste factor bij het assembleren van een hoge kwaliteit Lahoux nachtkijker, zoals de Lahoux LV-81 of de Lahoux Hemera, is een absoluut stofvrije en schone laboratorium atmosfeer. Het reinigen van de lenzen is een topprioriteit. Zelfs de kleinste onzuiverheden worden tijdens de kwaliteitscontrole gedetecteerd en zo wordt de beste nachtkijker door ons geselecteerd. ",
    work_2_title: "Assemblage",
    work_2_copy: "Onze medewerkers houden van de Lahoux producten. Daarom letten ze bij de montage op maximale precisie. Een precisie die u vervolgens ook tijdens de jacht zult ervaren. Minimale verschuiving van het trefpunt en maximale terugslagnauwkeurigheid.",
    work_3_title: "Kwaliteitscontrole",
    work_3_copy: "Elk Lahoux product doorloopt de strenge kwaliteitscontrole. Hier wordt elke functie en maatnauwkeurigheid nogmaals gecontroleerd. Alleen producten die 100 procent aan de normen voldoen, verlaten de productielijn.",
    work_4_title: "Hoge precisie",
    work_4_copy: "Wij gebruiken bij Lahoux hoge-precisie collimatie systemen bij de kwaliteitscontrole, om de maximale precisie en de hoogste nauwkeurigheid van onze Lahoux voorzetkijkers te verkrijgen. U kunt hier tijdens het schieten volledig op vertrouwen.",
    work_5_title: "ISO certificaat",
    work_5_copy: "OutdoorOptics heeft het ISO 9001.2015 certificaat, dat wil zeggen dat we bij OutdoorOptics in staat zijn de processen, producten en diensten continu te evalueren en te verbeteren. Bij ons wordt er dus voor 100% voldaan aan alle verplichtingen van deze betreffende ISO norm. De ISO norm stelt eisen aan het kwaliteitsmanagement van onze organisatie. In het kort gezegd moeten we dus aangeven wat we doen en wat de ISO norm zegt wat we moeten doen, tevens moeten we die zaken ook kunnen bewijzen. Op deze manier weten onze klanten wat ze kopen en zijn ze ervan verzekerd dat OutdoorOptics een constante kwaliteit levert.",    
    events_title: "Evenementen",
    events_none: "Momenteel zijn er geen evenementen gepland.",
    events_button: "Meer informatie",
    offers_hero_title: "Speciale aanbiedingen",
    offers_hero_copy: "",
    offers_page_heading: "Speciale aanbiedingen",
    offers_send_message_1: "Stuur een bericht naar",
    offers_send_message_2: "als je dit item wilt kopen of klik op de bestelknop.",
    service_title: "Servicebeleid",
    service_copy_1: "Bij normaal gebruik is er gedurende vele jaren geen onderhoud nodig aan uw OutdoorOptics kijker. Bent u een eindgebruiker en heeft u een probleem met uw OutdoorOptics kijker, neem dan contact op met uw dealer. ",
    service_copy_2: "Bent u een dealer en heeft u een OutdoorOptics kijker die niet goed functioneert? Neem dan ",
    service_copy_button: "contact",
    service_copy_3: " op met de service afdeling van OutdoorOptics.",
    service_choise_title: "Dealer in uw land",
    service_choise: "Selecteer uw land",
    specs_description: "Beschrijving",
    specs_voordelen: "Voordelen",
    specs_warning: "*Waarschuwing",
    specs_warning_p: "Let op de wettelijke voorschriften in uw respectieve federale staat of land.",
    specs_leveromvang: "Wordt standaard geleverd met de volgende accessoires:",
    specs_tech: "Technische specificaties", 
    specs_accessoires: "Accessoires bij dit product",
    specs_related: "Gerelateerde producten",
    specs_compatible: "Geschikt voor", 
    specs_new: "Nieuw",
    specs_artNum: "Artikelnummer",
    specs_color: "In tegenstelling tot warmtebeeld kijkers kunt u niet wisselen tijdens het spotten van kleur. U kiest permanent voor 1 kleurmodi.",
    specs_groen: "Groen",
    specs_fom_voor: "De",
    specs_fom: "is met verschillende restlichtversterker beeldbuizen (FOM) leverbaar.",
    specs_spots_veel: "Veel",
    specs_spots_weinig: "Weinig",
    specs_spots_geen: "Bijna geen",
    specs_price: "De prijs verschilt bij iedere beeldbuis / kleurmodi combinatie. Kiest u de combinatie die het beste bij u past.",
    vat_incl: "incl.",
    vat: "BTW",
    find_dealer: "Zoek dealer",
    product_detail: "Product details",
    contact_title: "Adres",
    contact_tel: "Tel",
    contact_p: "U kunt alleen op uitnodiging bij ons kantoor langskomen. Wij hebben geen winkel of showroom.",
    contact_mail: "E-mail",
    contact_dealers: "Alleen voor dealers",
    events_copy_1: "Maak kennis met Lahoux",
    events_copy_2: "Regelmatig bieden onze partners excursies aan waarbij u kennis kunt maken met de wereld van warmtebeeld kijkers. U kunt hier zelf ervaren waarom een warmtebeeld kijker een onmisbare tool is voor een beginnende maar zeker ook de ervaren vogelaar.",
    events_copy_3: "Ook staan wij zelf geregeld op beurzen waarbij wij u onze warmtebeeldkijkers kunnen demonstreren.",
    cookie_h1: "OutdoorOptics maakt gebruik van cookies",
    cookie_p: "OutdoorOptics maakt op deze website gebruik van functionele en geanonimiseerde analytische cookies. Wij plaatsen momenteel geen cookies van websites van derden en verzamelen geen persoonsgegevens van bezoekers. Meer informatie leest u in onze",
    cookie_consent: "Sluiten",
    products_not_found: "Geen producten gevonden voor deze categorie",
    Page_not_found: "De pagina die u zocht bestaat niet meer, ga verder naar home.",
    Netherlands: "Nederland",
    Austria: "Oostenrijk",
    Belgium: "België",
    Bulgaria: "Bulgarije",
    Cyprus: "Cyprus",
    Czech_republic: "Tsjechië",
    Denmark: "Denemarken",
    Estonia: "Estland",
    Finland: "Finland",
    France: "Frankrijk",
    Germany: "Duitsland",
    Greece: "Griekenland",
    Hungary: "Hongarije",
    Ireland: "Ierland",
    Italy: "Italië",
    Croatia: "Kroatië",
    Latvia: "Letland",
    Lithuania: "Litouwen",
    Luxembourg: "Luxemburg",
    Malta: "Malta",
    Poland: "Polen",
    Portugal: "Portugal",
    Romania: "Roemenië",
    Slovakia: "Slowakije",
    Sweden: "Zweden",
    United_kingdom: "Verenigd Koninkrijk",

    order_email: {
        subject_order: "Bestelling",
        body_opening: "Geachte klant,",
        body_copy_1: "Als u dit artikel wilt bestellen, vul dan hieronder uw gegevens in en verstuur deze e-mail.",
        body_product_title: "Product: ",
        body_details_heading: "Vul alstublieft uw gegevens in:",
        body_details_name: "Naam: ",
        body_details_address: "Verzendadres: ",
        body_details_zip: "Postcode: ",
        body_details_city: "Stad: ",
        body_details_country: "Land: ",
        body_details_phone: "Telefoonnummer: ",
        body_details_email: "E-mail: ",
        body_thanks: "Bedankt voor uw bestelling!",
    },

    cookie_policy: {
        page_heading: "Cookieverklaring",
        page_hero_copy: "",
        intro_p1: "OutdoorOptics maakt op deze website gebruik van functionele en analytische cookies en van local storage.",
        intro_p2: "Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. OutdoorOptics gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.",
        intro_p3: "Daarnaast plaatsen we analytische cookies. Analytische cookies verzamelen (vaak anoniem) gegevens en statistieken om te begrijpen hoe bezoekers de website gebruiken, zodat we de website en onze dienstverlening kunnen verbeteren.",
        uitschakelen_heading: "In- en uitschakelen van cookies en verwijdering daarvan",
        uitschakelen_p1: "U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.",
        uitschakelen_p2: "Meer informatie omtrent het in- en uitschakelen en het verwijderen van cookies kunt u vinden in de instructies van uw browser en/of met behulp van de Help-functie van uw browser.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. Wij gebruiken deze informatie om beslissingen te nemen over de inrichting van onze website. Tevens optimaliseren wij hiermee de werking van de website. Om zorgvuldige verwerking mogelijk te maken hebben we de volgende maatregelen getroffen:",
        analytics_listitem1: "We hebben een bewerkersovereenkomst met Google gesloten;",
        analytics_listitem2: "De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven;",
        analytics_listitem3: "We hebben alle mogelijkheden die Google aanbiedt om ‘gegevens met Google te delen’ uitgezet;",
        analytics_listitem4: "We maken geen gebruik van andere Google-diensten in combinatie met de Google Analytics-cookies.",
        analytics_p2: "Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet toegestaan de verkregen analytics informatie te gebruiken voor andere Google-diensten.",
        analytics_p3: "De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local storage is een vorm van lokale gegevensopslag, en is bedoeld om de website goed te laten werken. Wij gebruiken Local storage alleen om de door u gekozen taal van de website in uw browser te onthouden. Wij gebruiken local storage ook om te onthouden dat u de cookiebanner heeft gesloten, zodat deze bij uw volgende bezoek niet meer wordt weergegeven.",
        session_storage_heading: "Session storage",
        session_storage_p1: "Session storage is een vorm van tijdelijke opslag van gegevens, en is bedoeld om de website goed te laten werken.. Wij gebruiken session storage alleen om informatie over de gekozen taal van de website op te slaan in uw browser. De session storage wordt verwijderd nadat u uw browser heeft afgesloten.",
    }

   };