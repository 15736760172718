import React, { useState } from "react";
import { Link } from "react-router-dom";
import Burger from './Burger';
import styled from 'styled-components';
import logo from "./../../assets/OutdoorOptics-logo-rgb-WH_on_GR.png";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'


const Navbar = ({ clientCountry, showItems, clickHandlerShowItem }) => {
  
  return (
    <Nav>
      <div className="wrapNav">
        <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo OutdoorOptics" />
        </Link>
        </div>
        <Burger showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} clientCountry={clientCountry} />
      </div>
    </Nav>
  )
}

const Nav = styled.nav`
  width: 100%;
  position: relative;
  padding: 10px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-bg);

  @media (min-width: 1280px) {
    padding: 10px 20px;
  }

  @media (min-width: 1380px) {
    padding: 10px 40px;
  }
 
  @media (max-width: 1024px) {
      padding: 10px;
  }
  .logo {
    padding: 0 0;
    }
  .logo img{
      width: 250px;
      padding: 10px;
  }
@media (max-width: 380px) {
  .logo img{
	  width: 230px;
  }
}
  .wrapNav {
    display: flex;
    align-items: center;
    width: 100%;
  }

  div button, div p{
    border: none;
    background: none;
    padding: 3px;
    color: white;
  }

`

export default Navbar