import React from "react";
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import iconDealer from './../../assets/icons/dealersquare.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

function RightNav({open, showItems, clickHandlerShowItem, clientCountry }) {
  const { t, i18n } = useTranslation();
  const setLanguage = event => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    
            <Ul open={open}>   
                <Link to="/"> 
                  <li>{t("menu_home")}</li>
                </Link>         
                <div className="dropdown">
                  <Link to="/products" >
                    <li>{t("menu_producten")}</li>
                  </Link>
                  <div className="dropdown-content">

                    <Link to="/products" onClick={event => {
                    clickHandlerShowItem("thermal");}}>
                      <li>{t("menu_producten_warm")}</li>
                    </Link>
                    {/* <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("night");}}>
                      <li>{t("menu_producten_nacht")}</li>
                    </Link> */}
                    <Link to="/products" onClick={event => {
                    clickHandlerShowItem("digital");}}>
                      <li>{t("menu_producten_digi")}</li>
                    </Link>
                    {/* <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("accessories");}}>
                      <li>{t("menu_producten_acces")}</li>
                    </Link> */}
                    <a href="https://lahouxoptics.com" target="_blank" rel="noopener noreferrer">
                      <li>{t("menu_producten_overig")}</li>
                    </a>
                  </div>
                </div>
                <Link to="/technical">
                  <li>{t("menu_techniek")}</li>
                </Link>
                <Link to="/about">
                  <li>{t("menu_over")}</li>
                </Link>
                {/* <div className="dropdown">
                  <a href="#">
                    <li>{t("menu_over")}</li>
                  </a>
                  <div className="dropdown-content">
                    <Link to="/geschiedenis">
                      <li>{t("menu_over_history")}</li>
                    </Link>
                    <Link to="/hoe-wij-werken">
                      <li>{t("menu_over_how")}</li>
                    </Link>
                    <Link to="/evenementen">
                      <li>{t("menu_over_events")}</li>
                    </Link>
                  </div>
                </div> */}
                {/* <Link to="/vacatures">
                      <li>{t("menu_over_vacatures")}</li>
                    </Link> */}
                <Link to="/service">
                  <li>{t("menu_service")}</li>
                </Link>
                <Link to="/offers">
                  <li>{t("menu_offers")}</li>
                </Link>
                {/* <a href="https://portal.lahouxoptics.com/" target="_blank" className="menuLogin">
                  <li><img src={iconDealer} alt="icoon user"/> 
                  {t("betweenoption4")}</li>
                </a> */}
                    
      <div className="wrapLang">
      {/* <div className="showCountry"><p><FontAwesomeIcon icon={faMapMarkerAlt} /></p><p>{t(clientCountry)}</p></div> */}
      {/* For now no language switch yet */}
      <div className="setLang">
        
          {i18n.language !== "en" &&
            <button value='en' onClick={setLanguage} >
              {t("lang_en")}
            </button>
          }
          {i18n.language !== "nl" &&
            <button value='nl' onClick={setLanguage}>
              {t("lang_nl")}
            </button>
          }

          {i18n.language !== "de" &&
            <button value='de' onClick={setLanguage} >
              {t("lang_de")}
            </button>
          }
        
      </div>
      </div>
            </Ul>
  );
}
const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-left: 20px;

  li, .dropbtn {
    padding: 25px 25px;
    font-size: 1.5em;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @media (min-width: 1201px) { 
      font-size: 1.3em;
      padding: 25px 20px;
    }

    @media (min-width: 1355px) {
      font-size: 1.5em;
      padding: 25px 25px;
    }

  }

  a{
    text-decoration: none;
  }
  .dropdown-content {
    display: none;
    font-size: 14px;
    position: absolute;
    background-color: rgba(138, 138, 138, 60%);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
  }
  .dropdown:hover .dropdown-content, .dropdown:active .dropdown-content{
    display: block;
  }

  /* @media(hover: hover) and (pointer: fine) {
    .dropdown:hover .dropdown-content{
    display: block;
  }
} */

  .menuLogin{
    display: flex;
    flex-wrap: nowrap;
  }
  .menuLogin img{
    width: .75em;
    height: .75em;
    display: inline-block;
    margin-right: 4px;
  }

  .wrapLang, .showCountry{
    display: flex;
    align-items: center;
  }

  .wrapLang {
    margin-left: auto;
    font-size: 15px;
  }

  .setLang button{
    cursor: pointer;
    color: var(--text);
  }
  .setLang button:first-of-type::after{
    content: "    |" 
  }

  .showCountry{
    margin-right: 10px;
  }

  @media (max-width: 1200px) {
    flex-flow: column nowrap;
    background-color: var(--mobile-menu-bg);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    right: 0;
    top: 0;
    /* height: 70vh; */
    width: 300px;
    padding-top: 3.5rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
    z-index: 998;
    li {
      padding: 18px 10px;
    }
    .wrapLang {
     margin-left: 7px;
     margin-top: 20px;
  }
  .dropdown-content {
    position: relative;
  }
  }
`;
export default RightNav;



