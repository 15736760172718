import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const CookiePolicy = () => {
  const { t } = useTranslation();

  return (
    <StyledCookiePolicy>
      <Helmet>
        <title>{t("cookie_policy.page_heading")}</title>
      </Helmet>

      <div className="cookie-policy">
        <h1>{t("cookie_policy.page_heading")}</h1>

        <p>{t("cookie_policy.intro_p1")}</p>
        <p>{t("cookie_policy.intro_p2")}</p>
        <p>{t("cookie_policy.intro_p3")}</p>

        <h2>{t("cookie_policy.uitschakelen_heading")}</h2>
        <p>{t("cookie_policy.uitschakelen_p1")}</p>
        <p>{t("cookie_policy.uitschakelen_p2")}</p>

        <h2>{t("cookie_policy.analytics_heading")}</h2>
        <p>{t("cookie_policy.analytics_p1")}</p>

        <ul>
          <li>{t("cookie_policy.analytics_listitem1")}</li>
          <li>{t("cookie_policy.analytics_listitem2")}</li>
          <li>{t("cookie_policy.analytics_listitem3")}</li>
          <li>{t("cookie_policy.analytics_listitem4")}</li>
        </ul>

        <p>{t("cookie_policy.analytics_p2")}</p>
        <p>{t("cookie_policy.analytics_p3")}</p>

        <h2>{t("cookie_policy.local_storage_heading")}</h2>
        <p>{t("cookie_policy.local_storage_p1")}</p>
      </div>
    </StyledCookiePolicy>
  );
};

const StyledCookiePolicy = styled.div`
  margin: 60px auto 120px auto;
  max-width: 90vw;
  font-size: 16px;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 50vw;
  }

  .cookie-policy {
    max-width: 100vw;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 70vw;
    }

    @media (min-width: 1024px) {
      max-width: 50vw;
    }
  }

  .cookie-policy ul {
    list-style: disc;
    margin-left: 16px;
    margin-bottom: 20px;
    line-height: 18px;
  }

  .cookie-policy li {
    padding-bottom: 8px;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  p {
    line-height: 22px;
    margin-bottom: 20px;
  }

  .disclaimer h2 {
    margin-bottom: 20px;
  }
`;

export default CookiePolicy;
