import React, {useState} from "react"
import DataProducts from "./DataProducts"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import LinkProduct from "./LinkProduct";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

import {
    Link
} from "react-router-dom";


export const productArray = url => {
    return DataProducts.find(item => {
        return item.url === url;
    })
}

function ProductSpecs({clientCountry, clientVat}) {
    const { t, i18n } = useTranslation();
    const { url } = useParams()
    const data = productArray(url)

    const [fom, setFom] = useState("1000")
    const handleFom = event => setFom(event.target.value)

    const [color, setColor] = useState("Groen")
    const handleColor = event => setColor(event.target.value)

    const [spots, setSpots] = useState("veel")
    const handleSpots = event => setSpots(event.target.value)

    // exchange rate to calculate price from Dollar to Euro
    const exchangeRate = 1.05;
  
    return (
        <StyledSpecs>
            {data === undefined ? null :
            <div className="productspec-container">
            <Helmet>
                <title>{data.title[i18n.language]}</title>
                <meta name="description" content={`Product information of ${data.title[i18n.language]}`} />
            </Helmet>
            
            <div>
                <div className="productInfo">
                    <h1>{data.title[i18n.language]}</h1>
                    {data.available_date && <p className="available-date">{data.available_date[i18n.language]}</p>}
                    <div className="subtext">
                        <p>{data.subtitle}</p>
                        {data.price &&
                            <div>
                                <p className="price">
                                    {/* // Product price in Euro incl vat without exchange rate for Guide and Infiray Mate MAL25, MAL38 and MAH50  */}
                                    {(data.url.startsWith("guide-")) || (data.url === "mate-MAL25" || data.url === "mate-MAL38" || data.url === "mate-MAH50") ?
                                        <span className="amount">€ {Math.round(data.price)}</span>
                                    :
                                    /* Product price in US dollar incl vat devided by exchange rate for all other InfiRay */
                                        <span className="amount">€ {Math.round(((data.price) / (exchangeRate)))}</span>
                                    }
                                    <span className="vat"> ({t("vat_incl")} {t("vat")})</span>
                                </p>
                                
                                {/* <p className="price"><span className="amount">€ {((data.price) *(1+clientVat/100)).toFixed(2)}</span> <span className="vat">({t("vat_incl")}{clientVat}% {t("vat")} {t(clientCountry)})</span></p>  */}
                                {/* {console.log(typeof(Number(data.price)) + "dit is number(data.price) ")} */}
                                {/* {data.number && <p className="article-number">{t("specs_artNum")}: {data.number}</p>} */}
                                <br />
                                {/* <Link to="../../dealers" className="button-find-dealer">{t("find_dealer")}</Link> */}
                            </div>
                        }
                        <div className="longtext">
                            {data.longDes_1 &&  <h2>{t("specs_description")} </h2>}
                            {data.longDes_1 && <p>{data.longDes_1[i18n.language]}</p>}
                            {data.longDes_2 && <p>{data.longDes_2[i18n.language]}</p>}
                            {data.longDes_3 && <p>{data.longDes_3[i18n.language]}</p>}
                            {data.longDes_4 && <p>{data.longDes_4[i18n.language]}</p>}
                            {data.pros &&                             
                                <div>
                                    <h2>{t("specs_voordelen")}</h2>
                                    <ul>
                                        {data.pros[i18n.language].map(item => ( 
                                        <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        {data.leveromvang && 
                        <div>     
                            <h2>{t("specs_accessoires")}</h2>
                      
                            <p>{t("specs_leveromvang")} {data.accessoires.join(", ")}. </p><br />
                            </div>
                        }
                        </div>
                        {data.options &&
                            <div>
                                <p>{t("specs_color")}</p>
                                <ul className="optionsNight">
                                <label className={color === "Groen" ? "active" : ""}>{t("specs_groen")}
                                        <input type="radio" onChange={handleColor} checked={color==="Groen"} value="Groen"/>
                                        </label>
                                        <label className={color === "Onyx" ? "active" : ""}>Onyx
                                        <input type="radio" onChange={handleColor} checked={color==="Onyx"} value="Onyx"/>
                                        </label>
                                </ul>
                                <p>{t("specs_fom_voor")} {data.title[i18n.language]} {t("specs_fom")}</p>
                                <ul className="optionsNight">
                                    {data.url === "lvs-7" && 
                                    <div>
                                        <label className={fom === "1000" ? "active" : ""}>Minimum: 1000
                                            <input type="radio" onChange={handleFom} checked={fom==="1000"} value="1000"/>
                                        </label>
                                        <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                            <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                        </label>
                                        <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                        <   input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                        </label>
                                       
                                    </div>
                                    }  
                                     {data.url === "lvs-14" &&  
                                       
                                        <div>
                                            <label className={fom === "1000" ? "active" : ""}>Minimum: 1000
                                                <input type="radio"  onChange={handleFom} checked={fom==="1000"} value="1000"/>
                                            </label>
                                            <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                                <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                            </label>
                                            <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                                <input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                            </label>
                                            <label className={fom === "1700" ? "active" : ""}>Minimum: 1700
                                                <input type="radio" onChange={handleFom} checked={fom==="1700"}  value="1700"/>
                                            </label>
                                            <label className={fom === "1750" ? "active" : ""}>Minimum: 1750
                                                <input type="radio" onChange={handleFom} checked={fom==="1750"}  value="1750"/>
                                            </label>
                                            <label className={fom === "1800" ? "active" : ""}>Minimum: 1800
                                                <input type="radio" onChange={handleFom} checked={fom==="1800"}  value="1800"/>
                                            </label>
                                            <label className={fom === "1900" ? "active" : ""}>Minimum: 1900
                                                <input type="radio" onChange={handleFom} checked={fom==="1900"}  value="1900"/>
                                            </label>
                                            <label className={fom === "1950" ? "active" : ""}>Minimum: 1950
                                                <input type="radio" onChange={handleFom} checked={fom==="1950"}  value="1950"/>
                                            </label>
                                        </div>
                                    }
                                    {data.url === "lvs-81" &&  
                                       
                                       <div>
                                           <label className={fom === "1000" ? "active" : ""}>Minimum: 1000
                                               <input type="radio"  onChange={handleFom} checked={fom==="1000"} value="1000"/>
                                           </label>
                                           <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                               <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                           </label>
                                           <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                               <input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                           </label>
                                           <label className={fom === "1700" ? "active" : ""}>Minimum: 1700
                                               <input type="radio" onChange={handleFom} checked={fom==="1700"}  value="1700"/>
                                           </label>
                                           <label className={fom === "1750" ? "active" : ""}>Minimum: 1750
                                               <input type="radio" onChange={handleFom} checked={fom==="1750"}  value="1750"/>
                                           </label>
                                           <label className={fom === "1800" ? "active" : ""}>Minimum: 1800
                                               <input type="radio" onChange={handleFom} checked={fom==="1800"}  value="1800"/>
                                           </label>
                                           <label className={fom === "1900" ? "active" : ""}>Minimum: 1900
                                               <input type="radio" onChange={handleFom} checked={fom==="1900"}  value="1900"/>
                                           </label>
                                           <label className={fom === "1950" ? "active" : ""}>Minimum: 1950
                                               <input type="radio" onChange={handleFom} checked={fom==="1950"}  value="1950"/>
                                           </label>
                                       </div>
                                   }

                                </ul>
                                <p>{t("specs_price")}</p>
                                <ul className="optionsNight">
                                    <label className={spots === "veel" ? "active" : ""}> {t("specs_spots_veel")}
                                        <input type="radio" onChange={handleSpots} checked={spots==="veel"} value="veel"/>
                                    </label>
                                    <label className={spots === "weinig" ? "active" : ""}> {t("specs_spots_weinig")}
                                        <input type="radio" onChange={handleSpots} checked={spots==="weinig"} value="weinig"/>
                                    </label>
                                    <label className={spots === "geen" ? "active" : ""}> {t("specs_spots_geen")}
                                        <input type="radio" onChange={handleSpots} checked={spots==="geen"}  value="geen"/>
                                    </label>
                                </ul>
                                <div>
                                    <p className="price"><span className="amount">€ {Math.round((Number(data.options[color][fom][spots].price)*(1+clientVat/100)))}</span> <span className="vat">({t("vat_incl")}{clientVat}% {t("vat")} {t(clientCountry)})</span></p>
                                    <p>{t("specs_artNum")}: {data.options[color][fom][spots].number}</p>
                                    
                                </div>
                            </div>
                        }
                    </div>

                </div>
                {data.techs &&
                    <div>
                        <div className="techs">
                            <h2>{t("specs_tech")}</h2>
                            {data.flyer&& 
                            <a href={"/productflyers/"+ data.flyer} download > <FontAwesomeIcon icon={faDownload} />Download productflyer PDF</a>
                            }
                        </div>
                        <div className="specs">
                            <div>

                                <div>{Object.keys(data.techs).map((key) => <div key={key}><h3>{key}</h3><ul>{Object.keys(data.techs[key][i18n.language]).map(spec => (
                                    <li key={spec}><p>{data.techs[key][i18n.language][spec]}</p><p>{spec}</p></li>
                                ))}</ul></div>)}</div>
                                <ul>

                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {/* {data.type === "thermal" &&
                    <div className="dri">
                        <h2>DRI waarden</h2>
                        <img src={dri} alt="dri waarden per uitvoering warmtebeeldkijker" className="driImage"/>
                    </div>
                } */}

                {data.accessories &&
                    <div>
                        <h2>{t("specs_accessoires")}</h2>
                        <div className="products-cards"> 
                        {data.accessories.map( item => (
                            <LinkProduct key={item} id={item}/>
                        )

                        )}
                        </div>
                    </div>
                }
                {data.related &&
                    <div>
                        <h2>{t("specs_related")}</h2>
                        <div className="products-cards">
                        {data.related.map( item => (
                            <LinkProduct key={item} id={item}/>
                        )

                        )}</div>
                   
                    </div>
                }
                {data.compatible &&
                    <div>
                        <h2>{t("specs_compatible")}</h2>
                        <div className="products-cards">
                        {data.compatible.map( item => (
                            <LinkProduct key={item} id={item}/>
                        )

                        )}</div>
                   
                    </div>
                }
            </div>
            </div>
            }
        </StyledSpecs>
    )
}
const StyledSpecs = styled.div`
 max-width: 100vw;

 .productspec-container {
    padding: 20px;
    @media (min-width: 768px) {
        max-width: 80vw;
        
    }

    @media (min-width: 1400px) {
        max-width: 40vw;
    }
    margin: 100px auto 0 auto;

 }

    h1, h2  {
        border-bottom: 2px solid var(--brand-accent-color);
        display: inline-block;
        padding-bottom: 6px;
        font-size: 28px;
        font-weight: bold;
        
    }
    h1{
        margin-bottom: 5px;
        font-size: 28px;
    }
    h2{
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: 20px;
    }
    p{
        font-size: 20px;
        line-height: 22px;
    }
    .productInfo h1 {
        margin-bottom: 22px;
    }
    .available-date {
        font-style: italic;
        font-size: 18px;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    .subtext{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
    }
    .longtext{
        line-height: 18px;
        margin-bottom: 15px;
    }
    .longtext p:first-of-type, .longtext p:last-of-type {
        margin-bottom: 15px;
    }
    .longtext li {
        margin-bottom: 10px;
        list-style: disc;
        margin-left: 14px;
        font-size: 17px;
    }
    .techs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .techs a:link, .subtext a{
        color: var(--link);
        border: 1px solid #fff;
        font-size:  15px; 
        font-weight: bold; 
        letter-spacing: 1.5px;
  }
 
    .techs a:hover, .subtext a:hover{
        background-color: var(--link-hover);
    }
    .techs a:hover svg{
        color: var(--link);
    }

    a:link, a:visited, a:hover, a:active{
    text-decoration: none;
    color: var(--link);
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
   }

   .productInfo a:hover, a:active, .techs a:hover, a:active {
    background-color: var(--link-hover);
    border: 1px solid var(--link-hover);
   }

   .techs svg {
    margin-right: 10px;
    }

    .specs li{
        list-style: none;
        width: 80vw;
        margin-bottom: 10px;
        @media (min-width: 768px) {
            min-width: 30vw;
          }
        
          @media (min-width: 1024px) {
            width: 13vw;
          }
    }
    .specs h3{
        color: var(--text);
        border-bottom: 1px solid rgb(193, 184, 184);
        font-size: 24px;
        margin-bottom: 5px;
    }
 
    .specs li p:first-of-type{
        font-size: 20px;
    }
    .specs li p:last-of-type{
        font-size: 14px;
    }
    .specs ul{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            flex-wrap: wrap;
          }
        
          @media (min-width: 1024px) {
            flex-wrap: wrap;
          }
    }
    .optionsNight label{
        padding: 10px;
        font-size: 20px;
        border: 1px solid var(--brand-accent-color);
        display: block;
        border-radius: 10px;
        margin-top: 10px;
        margin-right: 15px;
        text-align: center;
        
    }
    .optionsNight .active{
        border: 4px solid var(--brand-accent-color);
    }
    .optionsNight label> input:checked{
        border: 4px solid var(--brand-accent-color);
    }


    .optionsNight li:first-of-type{
        border: 4px solid var(--brand-accent-color);
    }
    .optionsNight, .optionsNight div{
        display: flex;
        flex-wrap: wrap;
    }
    .optionsNight{
        margin-bottom: 40px;
    }

    .optionsNight input{
        visibility: hidden;
    }

    .optional{
        overflow: hidden;
        color: #988F8F;
    }
    .lens{
        
        max-width: 100%;
    }


    .price {
        margin-bottom: 14px;
    }
    .price .amount {
        font-weight: bold;
        margin-right: 6px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #fff;
    }
    .price .vat {
        font-size: 14px;
        font-weight: normal;
    }
    .article-number, .ean-code {
        font-size: 17px;
    }

    .subtext .button-find-dealer {
        margin-bottom: 30px;
    }

    .optional h3{
        font-weight: bold;
        margin-top: 20px;
        border-bottom: 2px solid var(--brand-accent-color);
        margin-bottom: 5px;
        display: inline-block;
        padding-bottom: 6px;
        color: #170E0E;
    }
    .dri{
        margin-bottom: 80px;
    }
    .driImage{
        max-width: 100%;
    }
    .FXoWx .sliderimg{
        height: 100%!important;
    }
    .products-cards{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        
    }


    `
export default ProductSpecs