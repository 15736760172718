import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const SelectShow = ({ showItems, clickHandlerShowItem }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;

  return (
    <StyledSelectShow>
      <div className="buttons">
        <button
          className={showItems === "thermal" ? "active" : "inactive"}
          onClick={(event) => {
            clickHandlerShowItem("thermal");
          }}
        >
          {t("menu_producten_warm")}
        </button>
        <button
          className={showItems === "night" ? "active" : "inactive"}
          onClick={(event) => {
            clickHandlerShowItem("night");
          }}
        >
          {t("menu_producten_nacht")}
        </button>
        {(path === "/products" || path === "/technical") && (
          <button
            className={showItems === "digital" ? "active" : "inactive"}
            onClick={(event) => {
              clickHandlerShowItem("digital");
            }}
          >
            {t("menu_producten_digi")}
          </button>
        )}
        {/* {path == "/producten" &&
                    <button className={showItems === "accessories" ? "active" : "inactive"} onClick={event => {
                        clickHandlerShowItem("accessories");
                        }}>{t("menu_producten_acces")}
                    </button>    
                } */}
      </div>
    </StyledSelectShow>
  );
};
const StyledSelectShow = styled.div`
  button {
    padding: 10px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 10px;
    min-width: 40vw;
    margin: 10px;
    font-size: 16px;
    color: var(--text);
    @media (min-width: 768px) {
      margin: 0;
      font-size: 25px;
      min-width: 200px;
      border-radius: 0;
    }
  }

  button:focus {
    outline: 0;
  }

  .active {
    background-color: var(--brand-accent-color);
    border-color: var(--brand-accent-color);
    @media (min-width: 768px) {
      text-decoration: underline var(--brand-accent-color);
      border-color: #fff;
      border-bottom: none;
      background-color: transparent;
    }
  }

  .inactive {
    @media (min-width: 768px) {
      border: none;
      border-bottom: 1px solid #fff;
    }
  }

  .buttons .inactive:hover, .buttons .active:hover {
    background-color: var(--brand-accent-color);
    border-color: var(--brand-accent-color);
}

  .buttons {
    margin: 30px auto 0px auto;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      margin: 40px auto 0px auto;
    }
  }
`;
export default SelectShow;
